import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class MediaManagerService {

  constructor(private apiService: ApiService) { }

  getAvailableImages() {
    return this.apiService.getData(environment.mediaPrefix + '/v1/media/notification-images');
  }

  uploadImage(image: any): Observable<any> {
    return this.apiService.postData(environment.mediaPrefix + '/v1/media/notification-images', image);
  }
}
