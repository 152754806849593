import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Participant, ParticipantProgress } from '../models/interfaces/participant.interface';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { StatusUpdateInterface } from '../models/interfaces/status-update.interface';
import { SendSMSTemplateBodyInterface } from '../models/interfaces/send-sms-template.interface';

@Injectable({
  providedIn: 'root',
})
export class ParticipantApiService {
  constructor(private readonly apiService: ApiService) {
  }

  addParticipant(trialId: number, participant: Participant): Observable<any> {
    return this.apiService.postData(environment.apiPrefix + `/v1/trials/${trialId}/participants`, participant);
  }

  updateStepStatus(trialId: number, stepId: number, participantId: number, statusId: number, reason: string) {
    const body: StatusUpdateInterface = {
      step_id: stepId,
      status_id: statusId,
      reason: reason,
    };
    return this.apiService.putData(environment.apiPrefix + `/v1/trials/${trialId}/participants/${participantId}/status`, body);
  }

  sendSMSTemplateToParticipant(trialId: number, participantId: number, templateId: number): Observable<any> {
    const body: SendSMSTemplateBodyInterface = {
      type: 'trial',
      template_id: templateId,
    };
    return this.apiService.postData(environment.apiPrefix + `/v1/trials/${trialId}/participants/${participantId}/sms/send`, body);

  }

  getAllParticipants(trialId: number): Observable<any[]> {
    return this.apiService.getData<Participant[]>(`${environment.apiPrefix}/v1/trials/${trialId}/participants`);
  }

  removeParticipant(trialId: number, participantId: number, reasonId: number, reasonText: string): Observable<any> {
    return this.apiService.patchData(`${environment.apiPrefix}/v1/trials/${trialId}/participants/${participantId}/remove`, {
      reason_id: reasonId,
      reason_text: reasonText,
    });
  }

  getParticipantProgress(trialId: number, participantId: number): Observable<ParticipantProgress[]> {
    return this.apiService.getData(`${environment.apiPrefix}/v1/trials/${trialId}/participants/${participantId}/progress?all_steps=true`);
  }
}
