<form *ngIf="templateType === 'sms'" [formGroup]="form" (submit)="updateOrSave()" class="smsTemplateForm">
  <div class="smsFormHeader">
    <h2>{{templateId === 'NEW' ? 'Create' : 'Update'}} Sms Template</h2>
  </div>
  <div class="p-field">
    <label for="templateTitle">Template Name</label>
    <div class="p-inputgroup">
      <input id="templateTitle" type="text" formControlName="title" required pInputText
        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
        data-automationid="tms-create-smsTemplateTitle-input" (keyup)="checkValidity()">
    </div>
    <p-messages [(value)]="titleMessages" [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'"
      [closable]="false"></p-messages>

  </div>
  <div class="p-field">
    <label for="templateDescription">Template Description</label>
    <input id="templateDescription" type="text" formControlName="description" required pInputText
      class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
      data-automationid="tms-create-smsTemplateDesc-input" (keyup)="checkValidity()">
    <p-messages [(value)]="descriptionMessages" [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'"
      [closable]="false"></p-messages>
  </div>

  <div class="smsBody">
    <div class="p-field container">
      <label for="templateBody">Template Body</label>
      <textarea pInputTextarea id="templateBody" type="text" formControlName="body" required
        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
        data-automationid="tms-create-smsTemplateBody-input" (keyup)="updateTextStats()"></textarea>
      <label>{{1000 - numberOfChars}} characters remaining </label>
      <p-messages [(value)]="bodyMessages" [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'"
        [closable]="false"></p-messages>
    </div>
  </div>

  <div class="templateButtonGroup">
    <p-button class="saveButton" styleClass="p-button-secondary" icon="pi pi-save" label="Save"
      (onClick)="updateOrSave()" data-automationid="tms-create-comm-saveButton"></p-button>
    <p-button label="Clear" styleClass="p-button-warning" icon="pi pi-trash" (onClick)="clearTemplate()"
      data-automationid="tms-create-smsTemplateClearButton"></p-button>
    <p-button type="button" label="Close" styleClass="p-button-danger" icon="pi pi-times" (onClick)="closeTemplate()"
      data-automationid="tms-create-smsTemplateCloseButton"></p-button>
  </div>
</form>

<div *ngIf="templateType == 'landing-page'">
  <app-info-screen-builder [commID]="templateId" (TemplateSaveComplete)="closeTemplate()"></app-info-screen-builder>
</div>
