import {Component, Input} from '@angular/core';
import {TrialInterface} from '../../models/interfaces/trial.interface';
import {OverlayPanel} from 'primeng/overlaypanel';

@Component({
  selector: 'app-trial-heading',
  templateUrl: './trial-heading.component.html',
  styleUrls: ['./trial-heading.component.scss'],
})
export class TrialHeadingComponent {
  @Input()
  trialDetails: TrialInterface | undefined;
  @Input()
  loading!: boolean;

  statuses = ['draft', 'ready', 'approved', 'published', 'paused', 'archived'];
  selectedStatus?: string;

  async onStatusChange(event: any, op2: OverlayPanel) {
    this.trialDetails!.status = this.selectedStatus;
    // not implemented yet
    // await this.trialApiService.UpdateTrial(this.trialDetails);
    op2.hide();
  }
}
