<div *ngIf="createEditForm">
  <h2>Edit {{editedModule.type}}</h2>
  <div [ngSwitch]="editedModule.type">
    <!-- Title Edit Starts -->
    <article *ngSwitchCase="notificationDataTypes[0].type">
      <input pInputText class="full-width" [disabled]="createEditForm.disabled" [(ngModel)]="editedModule.content" />
    </article>
    <!-- Title Edit Ends -->
    <!-- Body Text Edit Starts -->
    <article *ngSwitchCase="notificationDataTypes[1].type">
      <textarea pInputTextarea class="full-width" [disabled]="createEditForm.disabled"
        [(ngModel)]="editedModule.content"></textarea>
    </article>
    <!-- Body Text Edit Ends -->
    <!-- Image Edit Starts -->
    <article *ngSwitchCase="notificationDataTypes[2].type">
      <app-image-manager [chosenImage]="editedModule.content"
        (updateImage)="handleImageSelect($event)"></app-image-manager>
    </article>
    <!-- Image Edit Ends -->
    <!-- Video Edit Starts -->
    <article *ngSwitchCase="notificationDataTypes[3].type">
      <div *ngIf="editedModule.content === notificationDataTypes[3].content">
        <img [src]="editedModule.content" />
        <input pInputText type="text" placeholder="YouTube ID" class="full-width" [disabled]="createEditForm.disabled"
          [(ngModel)]="videoNodeID" />
        <p-button class="full-width" label="Add video" [disabled]="createEditForm.disabled"
          (click)="addVideo(videoNodeID)"></p-button>
      </div>
      <div *ngIf="editedModule.content !== notificationDataTypes[3].content">
        <iframe *ngIf="editedModule.tempData.sanitizedURL" [src]="editedModule.tempData.sanitizedURL" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
        <p-button class="full-width" label="Remove video" [disabled]="createEditForm.disabled"
          (click)="removeVideo()"></p-button>
      </div>
    </article>
    <!-- Video Edit Ends -->
    <!-- CTA Button Edit Starts -->
    <article *ngSwitchCase="notificationDataTypes[4].type">
      <input class="full-width" pInputText [disabled]="createEditForm.disabled"
        [(ngModel)]="editedModule.content.label" />
      <div *ngFor="let option of availableButtonOptions; let i = index;" class="radio-group-holder">
        <p-radioButton [value]="option.value" [disabled]="createEditForm.disabled"
          [(ngModel)]="editedModule.content.type" [inputId]="'cta-btn-' + i"
          (onClick)="handleCTAChange($event)"></p-radioButton>
        <label [for]="'cta-btn-' + i" class="ml-2">&nbsp;{{option.label}}</label>
        <!-- Select Algorithm Autocomplete Starts -->
        <div *ngIf="loadingAvailableAlgorithms && option.value === 'symptom-checker'">
          <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="8" fill="var(--surface-ground)"
            animationDuration=".5s"></p-progressSpinner>
        </div>
        <div *ngIf="!loadingAvailableAlgorithms && filteredAlgorithms && option.value === 'symptom-checker'">
          <p-dropdown placeholder="Select an Algorithm" [options]="filteredAlgorithms"
            [(ngModel)]="editedModule.content.triage_id" optionValue="id" [virtualScroll]="true"
            [virtualScrollItemSize]="38" class="full-width-input" [filter]="true" filterBy="id">
            <ng-template let-algo pTemplate="selectedItem">
              ID: {{algo.id}} NAME: {{algo.title}}
            </ng-template>
            <ng-template let-algo pTemplate="item">
              ID: {{algo.id}} NAME: {{algo.title}}
            </ng-template>
          </p-dropdown>
        </div>
        <!-- Select Algorithm Autocomplete Ends -->
        <!-- Select Product Autocomplete Starts -->
        <div *ngIf="loadingAvailableCTAProducts && option.value === 'buy-products'">
          <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="8" fill="var(--surface-ground)"
            animationDuration=".5s"></p-progressSpinner>
        </div>
        <div *ngIf="!loadingAvailableCTAProducts && filteredCTAProducts && option.value === 'buy-products'">
          <p-multiSelect optionLabel="name" optionValue="id" class="full-width-input" display="chip"
            [(ngModel)]="editedModule.content.product_ids" [options]="filteredCTAProducts"></p-multiSelect>
        </div>
        <!-- Select Product Autocomplete Ends -->
        <!-- External Link Input Starts -->
        <div *ngIf="showButtonExternalLink && option.value === 'external-link'">
          <input class="full-width" pInputText [disabled]="createEditForm.disabled"
            [(ngModel)]="editedModule.content.link.url" />
          <div><label>Webview:</label></div>
          <p-dropdown [(ngModel)]="editedModule.content.link.web_view" [options]="webLinkOptions" optionLabel="label"
            optionValue="value" placeholder="Select a webview option"></p-dropdown>
        </div>
      </div>
    </article>
    <!-- CTA Button Edit Ends -->
    <!-- Product Edit Starts -->
    <article *ngSwitchCase="notificationDataTypes[5].type">
      <div *ngIf="loadingAvailableProducts">
        <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="8" fill="var(--surface-ground)"
          animationDuration=".5s"></p-progressSpinner>
      </div>
      <div *ngIf="!loadingAvailableProducts && filteredProducts">
        <div class="product-selection-container">
          <p-dropdown class="full-width-input" [options]="filteredProducts" [(ngModel)]="editedModule.content"
            [virtualScroll]="true" [virtualScrollItemSize]="38" [filter]="true" filterBy="name"
            [autoDisplayFirst]="false" (onChange)="addModuleProduct($event)">
            <ng-template pTemplate="selectedItem">
              {{editedModule.content.name}}
            </ng-template>
            <ng-template let-product pTemplate="item">
              ID: {{product.id}} NAME: {{product.name}}
            </ng-template>
          </p-dropdown>
        </div>
        <div class="override-product-radio-container">
          <label>Override product description?</label>
          <div class="radio-group-holder">
            <p-radioButton inputId="product_description_override_yes" [disabled]="createEditForm.disabled"
              [(ngModel)]="editedModule.tempData.hasProductTextOverride" [value]="true"></p-radioButton>
            <label for="product_description_override_yes" class="ml-2">Yes</label>
          </div>
          <div class="radio-group-holder">
            <p-radioButton inputId="product_description_override_no" [disabled]="createEditForm.disabled"
              [(ngModel)]="editedModule.tempData.hasProductTextOverride" [value]="false"></p-radioButton>
            <label for="product_description_override_no" class="ml-2">No</label>
          </div>
        </div>
        <div *ngIf="editedModule?.tempData?.hasProductTextOverride">
          <div>
            <label>OVERRIDE TEXT</label>
          </div>
          <textarea class="full-width" pInputTextarea maxlength="130" [disabled]="createEditForm.disabled"
            [(ngModel)]="editedModule.custom_summary"></textarea>
        </div>
      </div>
    </article>
    <!-- Product Edit Ends -->
    <!-- Carousel Edit Starts -->
    <article *ngSwitchCase="notificationDataTypes[7].type" class="carousel-editing">
      <div class="carousel-slide-items">
        <ng-container *ngIf="(editedModule.content | typeof) !== 'string' && this.editedModule.content?.length > 0">
          <div class="slide-item" [class.selected]="editedModule.tempData.selectedCarouselOption.index === i"
            [class.invalid]="!item.message || item.message.length === 0 || !item?.image_url || item.image_url?.length === 0"
            *ngFor="let item of editedModule?.content; let i = index;" (click)="switchCarouselOption(i)">{{i + 1}}</div>
        </ng-container>
        <i class="pi pi-plus" *ngIf="editedModule?.content?.length < 6 || (editedModule?.content | typeof) === 'string'"
          (click)="addCarouselSlide()"></i>
      </div>
      <p class="mat-error"
        *ngIf="(editedModule.content | typeof) !== 'string' && this.editedModule.content?.length > 0 &&
        (!editedModule?.tempData?.selectedCarouselOption?.message || editedModule?.tempData?.selectedCarouselOption?.message?.length === 0 ||
        !editedModule?.tempData?.selectedCarouselOption?.image_url || editedModule.tempData.selectedCarouselOption.image_url?.length === 0)">
        Slide requires a image and a message.
      </p>
      <app-image-manager *ngIf="editedModule?.tempData?.selectedCarouselOption" [validation]="carouselValidation"
        [chosenImage]="editedModule.tempData.selectedCarouselOption.image_url"
        (updateImage)="handleCarouselImageSelect($event)"></app-image-manager>
      <div *ngIf="editedModule?.tempData?.selectedCarouselOption">
        <div>
          <label>TEXT</label>
        </div>
        <textarea class="full-width" pInputTextarea [disabled]="createEditForm.disabled"
          [(ngModel)]="editedModule.tempData.selectedCarouselOption.message" (ngModelChange)="onMessage()"></textarea>
      </div>
      <p-button label="Remove carousel slide"
        *ngIf="(editedModule.content | typeof) !== 'string' && editedModule?.content?.length > 0"
        [disabled]="createEditForm.disabled"
        (click)="removeSlide(editedModule.tempData.selectedCarouselOption.index)"></p-button>
    </article>
    <!-- Carousel Edit Ends -->
  </div>
  <p-button label="Remove element" styleClass="p-button-sm" [disabled]="createEditForm.disabled"
    (click)="removeModule(editedModuleIndex)"></p-button>
</div>
