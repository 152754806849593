import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private apiService: ApiService) { }

  getAllProducts(): Observable<any> {
    return this.apiService.getData(environment.apiPrefix + '/v1/products?exclude_triage=true');
  }
}
