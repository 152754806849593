<article class="trial-details-holder">
  <app-trial-heading [trialDetails]="trialDetails" [loading]="loading"></app-trial-heading>
  <div class="breadcrumbBar">
    <p-breadcrumb [model]="breadcrumbItems" [home]="breadcrumbHome"></p-breadcrumb>
    <p-button icon="pi pi-pencil"
              iconPos="left"
              label="Edit"
              *ngIf="trialDetails?.status === 'draft'"
              (click)="editTrial()"
              data-automationid="tms-trial-editButton">
    </p-button>
    <p-skeleton class="skeletonButton" *ngIf="loading"></p-skeleton>
  </div>

  <p-divider></p-divider>
  <p-tabView>
    <p-tabPanel header="Overview">
      <p-table [value]="participants"
               selectionMode="single"
               [paginator]="true"
               [rows]="20"
               [showCurrentPageReport]="true"
               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
               [rowsPerPageOptions]="[10, 20, 50, 100]"
               #participantsTable
      >
        <ng-template pTemplate="caption">
          <div class="participantTableHeader">
            <div class="left">Participants</div>
            <div class="right">
              <p-button icon="pi pi-plus"
                        iconPos="left"
                        label="Add Participant"
                        (click)="showAddParticipant()"
                        data-automationid="tms-trial-addParticipant"
              >

              </p-button>
              <span class="p-input-icon-left">
                  <i class="pi pi-filter"></i>
                  <p-dropdown placeholder="Filter Column"
                              [options]="filterOptions"
                              [(ngModel)]="filterColumn"
                              (ngModelChange)="filterParticipantsTable()"
                              styleClass="w-100"
                              data-automationid="tms-participant-filterColumn-dropdown"
                              [ngModelOptions]="{standalone: true}">
                  </p-dropdown>
                </span>
              <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <input type="text" pInputText
                         placeholder="Global Search"
                         [(ngModel)]="filterValue"
                         (ngModelChange)="filterParticipantsTable()"
                         data-automationid="tms-participant-filterValue-input"
                         [ngModelOptions]="{standalone: true}"
                  />
                </span>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="step_id">Step ID
              <p-sortIcon field="step_id"></p-sortIcon>
            </th>
            <th pSortableColumn="pet_id">Pet ID
              <p-sortIcon field="pet_id"></p-sortIcon>
            </th>
            <th pSortableColumn="pet_name">Pet Name
              <p-sortIcon field="pet_name"></p-sortIcon>
            </th>
            <th pSortableColumn="user_name">Owner Name
              <p-sortIcon field="user_name"></p-sortIcon>
            </th>
            <th pSortableColumn="pet_status">Pet Status
              <p-sortIcon field="pet_status"></p-sortIcon>
            </th>
            <th pSortableColumn="step_name">Step Name
              <p-sortIcon field="step_name"></p-sortIcon>
            </th>
            <th pSortableColumn="step_status">Step Status
              <p-sortIcon field="step_status"></p-sortIcon>
            </th>
            <th pSortableColumn="step_start_date">Step Start Date
              <p-sortIcon field="step_start_date"></p-sortIcon>
            </th>
            <th pSortableColumn="appointment_time">Appointment Time
              <p-sortIcon field="appointment_time"></p-sortIcon>
            </th>
            <th pSortableColumn="visibility_ends">Visibility Ends
              <p-sortIcon field="visibility_ends"></p-sortIcon>
            </th>
            <th pSortableColumn="duration_ends">Duration Ends
              <p-sortIcon field="duration_ends"></p-sortIcon>
            </th>
            <th pSortableColumn="actiosn">Actions
              <p-sortIcon field="actions"></p-sortIcon>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-participant>
          <tr (click)="viewParticipantView(participant)">
            <td>{{participant.step_id}}</td>
            <td>{{participant.id}}</td>
            <td data-automationid="tms-participants-pet-name-cell">
              {{participant.pet_name}}
              <i class="pi pi-info-circle"
                 style="cursor: help; float: right;"
                (click)="showParticipantInformation($event, this.participant)"
                 data-automationid="tms-participants-i-icon">
              </i>
            </td>
            <td>{{participant.user_name}}</td>
            <td data-automationid="tms-participants-pet-status-cell">
              <app-status-tag status="{{participant.pet_status}}"></app-status-tag>
            </td>
            <td>{{participant.step_name}}</td>
            <td>
              <app-status-tag status="{{participant.step_status}}"
                              (click)="changeStepStatus(participant.id,participant.step_id,$event)"
                              data-automationId="tms-participant-step-status"></app-status-tag>
            </td>
            <td>{{participant.step_start_date | date: 'fullDate'}}</td>
            <td>{{participant.appointment_time | date: 'EEEE, MMMM d, y, hh:mm a'}}</td>
            <td>{{participant.visibility_ends | date: 'fullDate'}}</td>
            <td>{{participant.duration_ends | date: 'fullDate'}}</td>
            <td>
              <p-button *ngIf="participant.pet_status.toLowerCase() !== 'removed'" styleClass="p-button-danger"
                icon="pi pi-trash" pTooltip="Remove participant" tooltipPosition="bottom" (click)="showRemoveParticipantModal($event, participant.id, trialId)"
                data-automationId="tms-participant-remove">
              </p-button>
            </td>
          </tr>
        </ng-template>

      </p-table>
    </p-tabPanel>
    <p-tabPanel header="Steps" data-automationId="tms-trial-step-tab">
      <app-trial-steps-overview [trialId]="trialId"
                                (navigationChange)="_breadcrumbService.addBreadcrumb({id: 'add-step', label: $event})"
                                [editEnabled]="trialDetails?.status === 'draft'"></app-trial-steps-overview>
    </p-tabPanel>
    <p-tabPanel header="Comms">
      <app-comm-add-edit [trialId]="trialId"></app-comm-add-edit>
    </p-tabPanel>
  </p-tabView>
  <app-step-status-overlay #statusOverlay (StatusUpdated)="getAllParticipants()"></app-step-status-overlay>
</article>
