import { Injectable } from '@angular/core';

export interface ImageValidationInterface {
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  maxHeight?: number;
  square?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ImageValidationService {

  validationConfig!: ImageValidationInterface;

  constructor() { }

  getImageValidationConfig(type: any) {
    switch (type) {
      case 'carousel':
      case 'icon':
      default:
        this.validationConfig = {
          minHeight: 1000,
          maxHeight: 2000,
          minWidth: 1000,
          maxWidth: 2000,
          square: true
        };
        break;
    }
    return this.validationConfig;
  }
}
