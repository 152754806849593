<div class="data-type-options-holder">
  <div
    cdkDropList
    cdkDropListConnectedTo="drop-list"
    *ngFor="let option of notificationDataTypes">
    <div class="option" cdkDrag [id]="option.type">
      <span
        *ngIf="option.type !== notificationDataTypes[2].type &&
        option.type !== notificationDataTypes[3].type &&
        option.type !== notificationDataTypes[4].type &&
        option.type !== notificationDataTypes[5].type &&
        option.type !== notificationDataTypes[6].type &&
        option.type !== notificationDataTypes[7].type" >
        {{option.label}}
      </span>
        <span *ngIf="option.type === notificationDataTypes[5].type" class="product-option">
        <i class="pi pi-shopping-cart"></i>
        <span>{{option.label}}</span>
      </span>
        <span
          *ngIf="option.type === notificationDataTypes[2].type ||
        option.type === notificationDataTypes[3].type"
          class="placeholder">
        <img alt="image content" [src]="option.content" />
      </span>
        <span *ngIf="option.type === notificationDataTypes[4].type">
        <button pButton
          class="notification-btn"
          icon="pi pi-angle-right"
          iconPos="right"
          [label]="option.label"></button>
      </span>
        <div *ngIf="option.type === notificationDataTypes[6].type">
          <hr>
        </div>
        <span *ngIf="option.type === notificationDataTypes[7].type && option.content === notificationDataTypes[7].content" class="carousel-placeholder">
        <img alt="carousel image" [src]="option.content" />
      </span>
    </div>
  </div>
</div>
