import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  ParticipantAdvancedSearchModalComponent,
} from './participant-advanced-search-modal/participant-advanced-search-modal.component';
import { PetSearchApiService } from '../../../shared/general-shared/services/petSearchApi.service';
import {
  PetSearchResult,
} from '../../../shared/general-shared/models/interfaces/petSearchResponse.interface';
import { Participant } from '../../../shared/general-shared/models/interfaces/participant.interface';
import { ParticipantApiService } from '../../../shared/general-shared/services/participantApi.service';
import { ErrorHandlerService } from '../../../shared/general-shared/services/error-handler.service';
import { concatAll, map, Observable, zip } from 'rxjs';

@Component({
  selector: 'app-participant-add-modal',
  templateUrl: './participant-add-modal.component.html',
})
export class ParticipantAddModalComponent implements OnInit {
  searchEmail = '';
  searchResults: PetSearchResult[] = [];
  displayResults: PetSearchResult[] = [];
  selectedItems: PetSearchResult[] = [];
  loading: boolean = false;
  showSelected: boolean = false;
  showIcon: string = 'pi pi-eye';
  showLabel: string = 'Show Selected';
  trialId!: number;
  phone!: string;

  constructor(private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private dialogService: DialogService,
    private petSearchApiService: PetSearchApiService,
    private participantApiService: ParticipantApiService,
    private errorHandlerService: ErrorHandlerService) {
  }

  ngOnInit(): void {
    this.trialId = this.config.data;
  }

  basicSearch() {
    this.search(this.searchEmail);
  }

  search(email: string, ownerName?: string, petName?: string) {
    this.loading = true;
    let response = this.petSearchApiService.searchForAPet(email, petName ?? '', ownerName ?? '');
    response.subscribe(result => {
      this.searchResults = result.results ?? [];

      this.selectedItems.forEach(selectedItem => {
        let existingSelection = this.searchResults.findIndex((pet) => pet.pet.id === selectedItem.pet.id);
        if (existingSelection >= 0)
          this.searchResults.splice(existingSelection, 1);
        selectedItem.selected = true;
      });
      this.displayResults = [...this.selectedItems, ...this.searchResults];
      this.loading = false;
    }, error => this.errorHandlerService.handleError(error));
  }

  advancedSearch() {
    let ref = this.dialogService.open(ParticipantAdvancedSearchModalComponent, { header: 'Advanced Search' });
    ref.onClose.subscribe(response => {
      this.search(response.email, response.ownerName, response.petName);
    });
  }

  toggleSelected() {
    this.showSelected = !this.showSelected;
    this.showIcon = this.showSelected ? 'pi pi-eye-slash' : 'pi pi-eye';
    this.showLabel = this.showSelected ? 'Hide Selected' : 'Show Selected';
  }

  addParticipants() {
    let listOfParticipants: Observable<any>[] = [];
    this.selectedItems.forEach((pet) => {
      let participant: Participant = {
        pet: pet.pet,
        user: pet.owner,
      };
      participant.pet.user_id = participant.user.id;
      participant.pet.age = participant.pet.age_in_months;

      listOfParticipants.push(this.participantApiService.addParticipant(this.trialId, participant));
    });
    zip(...listOfParticipants).subscribe({
      next: _ => {
        this.ref.close(true);
      },
      error: err => {
        this.errorHandlerService.handleError(err);
      },
    });
    this.ref.close(true);
  }
}
