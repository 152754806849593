import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class RulesService {

  constructor(private apiService: ApiService) { }

  getAvailableRules(): Observable<any> {
    return this.apiService.getData(environment.apiPrefix + '/v1/rules');
  }

  getEventTopics(): Observable<any> {
    return this.apiService.getData(environment.apiPrefix + '/v1/trials/comms/events');
  }

}
