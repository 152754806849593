import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SmsTemplateFormInterface } from '../../../shared/general-shared/models/interfaces/smsTemplate-form.interface';
import { Message } from 'primeng/api';
import { SmsTemplateApiService } from '../../../shared/general-shared/services/smsTemplateApi.service';
import { SmsTemplateInterface } from '../../../shared/general-shared/models/interfaces/smsTemplate.interface';
import { ErrorHandlerService } from '../../../shared/general-shared/services/error-handler.service';

@Component({
  selector: 'app-sms-template-add-edit',
  templateUrl: './sms-template-add-edit.component.html',
})
export class SmsTemplateAddEditComponent implements OnInit {
  form!: FormGroup<SmsTemplateFormInterface>;
  @Input()
  trialId!: number;
  @Input()
  selectedTemplate?: SmsTemplateInterface;
  @Input()
  templateId?: number | string | null | undefined;
  @Input()
  templateType?: string | null;
  @Output() TemplateSaveComplete = new EventEmitter();
  numberOfChars: number = 0;
  tagsUsed: string[] = [];
  bodyMessages: Message[] = [];
  titleMessages: Message[] = [];
  descriptionMessages: Message[] = [];
  maxSmsLength = 1000;

  constructor(private readonly smsTemplateApiService: SmsTemplateApiService, private readonly errorHandlerService: ErrorHandlerService) {
  }

  ngOnInit(): void {
    this.updateTemplate();
  }

  updateTemplate() {
    if (this.selectedTemplate) {
      this.form = new FormGroup<SmsTemplateFormInterface>({
        id: new FormControl(this.selectedTemplate.id),
        description: new FormControl(this.selectedTemplate.description),
        title: new FormControl(this.selectedTemplate.title),
        trial_id: new FormControl(this.selectedTemplate.trial_id),
        body: new FormControl(this.selectedTemplate.body),
      });

      this.updateTextStats();
      return;
    }
    this.form = new FormGroup<SmsTemplateFormInterface>({
      id: new FormControl(),
      description: new FormControl(),
      title: new FormControl(),
      trial_id: new FormControl(this.trialId),
      body: new FormControl(),
    });
  }

  updateOrSave() {
    this.form.controls.body.markAsDirty();
    this.form.controls.title.markAsDirty();
    this.form.controls.description.markAsDirty();
    this.checkValidity();
    if (this.form.invalid)
      return;
    if (!this.form.touched) {
      this.closeTemplate();
      return;
    }
    if (this.templateId === 'NEW') {
      this.smsTemplateApiService.saveTemplate(this.form.getRawValue()).subscribe((response) => {
        this.templateId = response;
        this.form.controls.id.setValue(response);
        this.closeTemplate();
      }, error => {
        this.errorHandlerService.handleError(error);
      });
    } else {
      this.smsTemplateApiService.updateTemplate(this.form.getRawValue()).subscribe((response) => {
        this.templateId = response;
        this.closeTemplate();
      }, error => {
        this.errorHandlerService.handleError(error);
      });
    }
  }

  updateTextStats() {
    const tagCharValue = 50;
    const text = this.form.value.body;
    this.tagsUsed = [];
    this.numberOfChars = 0;
    if (text) {
      this.numberOfChars = text.length;
      const regex = new RegExp('({.+?})', 'g');
      let m;
      while ((m = regex.exec(text)) !== null) {
        const tag = m[1];
        this.tagsUsed.push(tag);
        this.numberOfChars += tagCharValue - tag.length;
      }
    }
    const invalidLength = this.numberOfChars > this.maxSmsLength || this.numberOfChars === 0;
    this.form.controls.body.setErrors(invalidLength ? {
      SmsLengthInvalid: text,
    } : null);

    this.checkValidity();
  }

  checkValidity() {
    if (!this.form?.valid) {
      if (this.form?.controls.body.errors && this.form.controls.body.dirty) {
        if (this.bodyMessages.length === 0) {
          this.bodyMessages = [{
            severity: 'error',
            summary: 'Error',
            detail: `Sms Body must have a length between 0 and ${this.maxSmsLength}. (Any Tags count as 50 characters)`,
          }];
        }
      } else {
        this.bodyMessages = [];
      }
      if (this.form?.controls.title.errors && this.form.controls.title.dirty) {
        if (this.titleMessages.length === 0) {
          this.titleMessages = [{
            severity: 'error',
            summary: 'Error',
            detail: 'Title is required',
          }];
        }
      } else {
        this.titleMessages = [];
      }
      if (this.form.controls.description.errors && this.form.controls.description.dirty) {
        if (this.descriptionMessages.length === 0) {
          this.descriptionMessages = [{
            severity: 'error',
            summary: 'Error',
            detail: 'Description is required',
          }];
        }
      } else {
        this.descriptionMessages = [];
      }
    } else {
      this.bodyMessages = [];
      this.titleMessages = [];
      this.descriptionMessages = [];
    }
  }

  clearTemplate() {
    this.form = new FormGroup<SmsTemplateFormInterface>({
      id: new FormControl(this.selectedTemplate ? this.selectedTemplate.id : null),
      description: new FormControl(),
      title: new FormControl(),
      trial_id: new FormControl(this.trialId),
      body: new FormControl(),
    });
    this.updateTextStats()
  }

  closeTemplate() {
    this.TemplateSaveComplete.emit();
  }
}
