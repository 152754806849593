import { Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { FormControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { StepFormInterface } from '../../../shared/general-shared/models/interfaces/step-form.inteface';
import { StepApiService } from '../../../shared/general-shared/services/stepApi.service';
import { DecisionTreesService } from '../../../shared/general-shared/services/decision-trees.service';
import {
  DecisionTreeDisplay,
} from '../../../shared/general-shared/models/interfaces/decision-trees.interface';
import { ErrorHandlerService } from '../../../shared/general-shared/services/error-handler.service';
import { BreadcrumbService } from 'src/app/shared/general-shared/services/breadcrumb.service';
import { ConvertToHoursFrom } from 'src/app/shared/general-shared/pipes/convert-to-hours';
import { CommService } from '../../../shared/general-shared/services/comm.service';
import { RulesService } from '../../../shared/general-shared/services/rules.service';
import { SmsTemplateApiService } from '../../../shared/general-shared/services/smsTemplateApi.service';
import { EmailTemplatesService } from '../../../shared/general-shared/services/email-templates.service';
import { CommsTypeEnum } from '../../../shared/general-shared/models/interfaces/comms-type.enum';
import { Observable, zip } from 'rxjs';

@Component({
  selector: 'app-step-details',
  templateUrl: './step-add-edit.component.html',
  styleUrls: ['./step-add-edit.component.scss'],
})
export class StepAddEditComponent implements OnInit {
  form!: FormGroup<StepFormInterface>;
  units: any[] = [];

  @Input()
  noOfSteps!: number;

  @Input()
  trialId!: number;
  @Output()
  addComplete = new EventEmitter();
  commsTypeEnum = CommsTypeEnum;
  algorithms!: Array<DecisionTreeDisplay>;
  stepDetailsValid: boolean = true;
  ruleCommTypes: Array<string> = [this.commsTypeEnum.Email, this.commsTypeEnum.SMS, this.commsTypeEnum.LandingPage];
  availableRules: Array<any> = [];
  availableSMSTemplates: Array<any> = [];
  availableEmailTemplates: Array<any> = [];
  availableEventTopics: Array<any> = [];
  availableLandingPages: Array<any> = [];
  selectedRuleCommTypeOptions: Array<any> = [];
  deletedCommIds: Array<number> = []

  stepTypes = [
    'Questionnaire',
    'Study Call',
    'Internal Step',
    'Communication Only'
  ];

  constructor(
    private stepApiService: StepApiService,
    private decisionTreesService: DecisionTreesService,
    private errorHandlerService: ErrorHandlerService,
    private _breadcrumbService: BreadcrumbService,
    private rulesService: RulesService,
    private smsTemplateService: SmsTemplateApiService,
    private emailTemplateService: EmailTemplatesService,
    private commsService: CommService,
    @Optional() public ref?: DynamicDialogRef,
    @Optional() public config?: DynamicDialogConfig) {
  }

  ngOnInit(): void {
    if (this.ref !== null) {
      this.trialId = this.config?.data.trial_id;
    }
    const commsFormArray = new FormArray<any>([]);
    if (this.config?.data.comms) {
      if (this.config?.data.comms.length > 0) {
        this.config?.data.comms.forEach((item: any) => {
          const commFormGroup: FormGroup = new FormGroup({
            id: new FormControl(item.id ? item.id : null),
            type: new FormControl(item.type, Validators.required),
            email_template_id: new FormControl(item.email_template_id),
            sms_template_id: new FormControl(item.sms_template_id),
            notification_template_id: new FormControl(item.notification_template_id),
            rule_id: new FormControl(item.rule_id),
            delay: new FormControl(item.delay, Validators.required),
            event_topic: new FormControl(item.event_topic)
          });
          let chosenType;
          switch (item.type) {
            default:
            case this.commsTypeEnum.LandingPage:
              chosenType = 'notification_template_id';
              break;
            case this.commsTypeEnum.SMS:
              chosenType = 'sms_template_id';
              break;
            case this.commsTypeEnum.Email:
              chosenType = 'email_template_id';
              break;
          }
          commFormGroup.controls[chosenType].setValidators(Validators.required);
          commsFormArray.push(commFormGroup);
        });
      }
    }
    this.form = new FormGroup<StepFormInterface>({
      id: new FormControl(this.config?.data.id),
      trial_id: new FormControl(this.trialId),
      duration_unit_id: new FormControl(this.config?.data.duration_unit_id, [Validators.required]),
      name: new FormControl(this.config?.data.name, [Validators.required]),
      important: new FormControl(this.config?.data.important ?? false),
      duration: new FormControl(this.config?.data.duration, [Validators.required]),
      algorithm_id: new FormControl(this.config?.data.algorithm_id),
      visibility: new FormControl(this.config?.data.visibility, [Validators.required]),
      visibility_unit_id: new FormControl(this.config?.data.visibility_unit_id, [Validators.required]),
      step_number: new FormControl(this.config?.data.step_number || this.noOfSteps + 1, [Validators.required]),
      step_type: new FormControl(this.config?.data.step_type, [Validators.required]),
      show_in_timeline: new FormControl(this.config?.data.show_in_timeline ?? false),
      comms: commsFormArray
    });

    this.getAvailableComms();
    this.getAvailableEventTopics();
    this.getAvailableRules();
    this.getSMSTemplates();
    this.getEmailTemplates();

    this.stepApiService.GetPersistenceUnits().subscribe((units) => {
      this.units = units;
    });

    this.decisionTreesService.getAll().subscribe({
      next: (response) => {
        this.algorithms = response.trees.map(tree => {
          return { ...tree, label: `${tree.id}: ${tree.title}` };
        });
      },
      error: (error) => {
        this.errorHandlerService.handleError(error);
      },
    });
  }

  getAvailableComms() {
    this.commsService.getCommsTemplates(this.trialId).subscribe({
      next: (response) => {
        this.availableLandingPages = response;
      },
      error: (error) => {
        this.errorHandlerService.handleError(error);
      },
    });
  }

  getAvailableRules() {
    this.rulesService.getAvailableRules().subscribe({
      next: (response) => {
        this.availableRules = response;
      },
      error: (error) => {
        this.errorHandlerService.handleError(error);
      },
    });
  }

  getAvailableEventTopics() {
    this.rulesService.getEventTopics().subscribe({
      next: (response) => {
        this.availableEventTopics = response;
      },
      error: (error) => {
        this.errorHandlerService.handleError(error);
      },
    });
  }

  getSMSTemplates() {
    this.smsTemplateService.getAllTemplatesForTrial(this.trialId).subscribe({
      next: (response) => {
        this.availableSMSTemplates = response;
      },
      error: (error) => {
        this.errorHandlerService.handleError(error);
      },
    });
  }

  getEmailTemplates() {
    this.emailTemplateService.getAllEmailTemplates().subscribe({
      next: (response) => {
        this.availableEmailTemplates = response;
      },
      error: (error) => {
        this.errorHandlerService.handleError(error);
      },
    });
  }

  addStepRule() {
    // Defaulting to type SMS to enable displaying the template ID selection on init
    const newStepRule = new FormGroup({
      id: new FormControl(null),
      type: new FormControl(this.commsTypeEnum.SMS, Validators.required),
      email_template_id: new FormControl(null),
      sms_template_id: new FormControl(null, Validators.required),
      notification_template_id: new FormControl(null),
      rule_id: new FormControl(null),
      delay: new FormControl(0, Validators.required),
      event_topic: new FormControl(null)
    });
    this.stepRulesArray.push(newStepRule);
  }

  removeStepRule(index: number) {
    this.deletedCommIds.push(this.stepRulesArray.at(index).get('id')?.value);
    this.stepRulesArray.removeAt(index);
  }

  deleteListedComms() {
    let deleteComms: Array<Observable<any>> = [];

    this.deletedCommIds.forEach(id => {
      deleteComms.push(this.commsService.deleteComm(this.trialId, id));
    });

    zip(...deleteComms).subscribe({
      next: _ => { },
      error: err => {
        this.errorHandlerService.handleError(err.error);
      },
    });

    this.deletedCommIds = [];
  }

  handleCommTypeChange(event: any, index: number) {
    // Remove validators for controls before resetting based on type selection (resets values of other keys as well as to not cause confusion)
    this.stepRulesArray.controls[index].get('notification_template_id')?.removeValidators(Validators.required);
    this.stepRulesArray.controls[index].get('sms_template_id')?.removeValidators(Validators.required);
    this.stepRulesArray.controls[index].get('email_template_id')?.removeValidators(Validators.required);
    let chosenType;
    switch (event.value) {
      default:
      case this.commsTypeEnum.LandingPage:
        chosenType = 'notification_template_id';
        this.stepRulesArray.controls[index].get('sms_template_id')?.setValue(null);
        this.stepRulesArray.controls[index].get('email_template_id')?.setValue(null);
        break;
      case this.commsTypeEnum.SMS:
        chosenType = 'sms_template_id';
        this.stepRulesArray.controls[index].get('notification_template_id')?.setValue(null);
        this.stepRulesArray.controls[index].get('email_template_id')?.setValue(null);
        break;
      case this.commsTypeEnum.Email:
        chosenType = 'email_template_id';
        this.stepRulesArray.controls[index].get('notification_template_id')?.setValue(null);
        this.stepRulesArray.controls[index].get('sms_template_id')?.setValue(null);
        break;
    }
    this.stepRulesArray.controls[index].get(chosenType)?.setValidators(Validators.required);
  }

  async UpdateOrSave() {
    if (this.deletedCommIds.length > 0) this.deleteListedComms()

    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      control?.markAsTouched({ onlySelf: true });
    });

    if (this.form.invalid) return
    this._breadcrumbService.removeBreadcrumb('add-step')

    if (this.ref == null)
      await this.saveStep();
    else
      await this.updateStep();
  }

  async saveStep() {
    await this.stepApiService.CreateNewStep(this.form.getRawValue());
    this.addComplete.emit();
  }

  async updateStep() {
    await this.stepApiService.UpdateStep(this.form.getRawValue());
    this.ref!.close(true);
  }

  async deleteStep() {
    await this.stepApiService.DeleteStep(this.config?.data.trial_id, this.config?.data.id);
    this.ref!.close(true);
  }


  validateDetails() {
    if (this.units.length === 0) {
      this.stepDetailsValid = true;
      return;
    }
    const convertPipe = new ConvertToHoursFrom();
    const duration = convertPipe.transform(this.form.get('duration')!.value, this.units[this.form.get('duration_unit_id')!.value! - 1].plural);
    const visibility = convertPipe.transform(this.form.get('visibility')?.value!, this.units[this.form.get('visibility_unit_id')!.value! - 1].plural);

    if (visibility > duration) {
      this.stepDetailsValid = false;
      return;
    }
    this.stepDetailsValid = true;
    return;
  }

  cancel() {
    this._breadcrumbService.removeBreadcrumb('add-step')
    this.addComplete.emit();
  }

  get stepRulesArray() {
    return this.form.controls.comms as FormArray;
  }
}
