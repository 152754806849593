<form [formGroup]="form" class="commForm">
  <div class="formRowWithButtons">
    <div class="p-field">
      <label for="type">Comm Type</label>
      <p-dropdown id="type" [options]="commType" formControlName="type" data-automationid="tms-create-commType-dropdown"
        placeholder="Comm Type" (onChange)="clearSelectedTemplate()"></p-dropdown>
    </div>
    <div class="actions">
      <p-button class="newTemplateButton" styleClass="p-button-text newTemplateButton" icon="pi pi-file-edit"
        label="Create New Template" (onClick)="newTemplate()"
        *ngIf="form.controls.type.value && !form.controls.templateId.value"
        data-automationid="tms-create-comm-newTemplate"></p-button>

      <p-button styleClass="p-button-warning"
        *ngIf="form.controls.type.value === 'sms' && !form.controls.templateId.value" label="Send one off SMS"
        icon="pi pi-mobile" pTooltip="Send one off SMS template to participant" tooltipPosition="bottom"
        (click)="showSendSMSParticipantModal()">
      </p-button>
    </div>
  </div>

  <div *ngIf="form.controls.type.value === 'sms'">
    <label *ngIf="!form.controls.templateId.value">Select your template</label>
    <p-table [value]="availableSMSTemplates" selectionMode="single" styleClass="p-datatable-gridlines"
      [paginator]="true" [rows]="5" [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[5, 10, 20]"
      #templateSMSTable *ngIf="!form.controls.templateId.value">
      <ng-template pTemplate="caption">
        <div class="stepTableHeader">
          <div>Template</div>
          <div class="stepTableActions">
            <span class="p-input-icon-left">
              <i class="pi pi-filter"></i>
              <p-dropdown placeholder="Filter Column" [options]="['Title', 'Description', 'Body']"
                [(ngModel)]="filterColumn" (ngModelChange)="filterStepTable()" styleClass="w-100"
                data-automationid="tms-sms-filterColumn-dropdown" [ngModelOptions]="{standalone: true}">
              </p-dropdown>
            </span>
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input type="text" pInputText placeholder="Global Search" [(ngModel)]="filterValue"
                (ngModelChange)="filterStepTable()" data-automationid="tms-sms-filterValue-input"
                [ngModelOptions]="{standalone: true}" />
            </span>
          </div>
        </div>

      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th></th>
          <th pSortableColumn="id">
          </th>
          <th pSortableColumn="last_modified" data-automationid="tms-smsTemplate-table-createdDate-column">Creation Date
            <p-sortIcon field="last_modified"></p-sortIcon>
          </th>
          <th pSortableColumn="title" data-automationid="tms-smsTemplate-table-title-column">Title
            <p-sortIcon field="title"></p-sortIcon>
          </th>
          <th pSortableColumn="description" data-automationid="tms-smsTemplate-table-description-column">Description
            <p-sortIcon field="description"></p-sortIcon>
          </th>
          <th pSortableColumn="assignedSteps"> Assigned Steps</th>
          <th pSortableColumn="body" data-automationid="tms-smsTemplate-table-body-column">Body
            <p-sortIcon field="body"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-smsTemplate>
        <tr [pSelectableRow]="smsTemplate" (click)="templateSelected(smsTemplate, 'sms')">
          <td><span class="pi pi-plus"></span></td>
          <td data-automationid="tms-smsTemplate-table-id-item"> {{smsTemplate.id}}</td>
          <td> Last modified <br> {{smsTemplate.last_modified | date:'medium'}}</td>
          <td data-automationid="tms-smsTemplate-table-title-item">{{smsTemplate.title}}</td>
          <td data-automationid="tms-smsTemplate-table-description-item">{{smsTemplate.description}}</td>
          <td>

            <div class="stepTagHolder">
              <p-tag *ngFor="let step of smsTemplate.assignedSteps let i = index" class="tag{{i}}">{{step | titlecase}}
              </p-tag>
            </div>
          </td>
          <td data-automationid="tms-smsTemplate-table-body-item">{{smsTemplate.body}}</td>
        </tr>

      </ng-template>
    </p-table>
    <p-divider></p-divider>


  </div>
  <div *ngIf="form.controls.type.value === 'landing-page'">

    <label *ngIf="!form.controls.templateId.value">Select your template</label>
    <p-table [value]="availableInfoTemplates" selectionMode="single" styleClass="p-datatable-gridlines"
      [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 20, 30]" #templateLandingTable *ngIf="!form.controls.templateId.value">
      <ng-template pTemplate="caption">
        <div class="stepTableHeader">
          <div>Template</div>
          <div class="stepTableActions">
            <span class="p-input-icon-left">
              <i class="pi pi-filter"></i>
              <p-dropdown placeholder="Filter Column" [options]="['id', 'last_modified', 'name', 'step_id']"
                [(ngModel)]="filterColumn" (ngModelChange)="filterInfoScreenTable()" styleClass="w-100"
                [ngModelOptions]="{standalone: true}">
              </p-dropdown>
            </span>
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input type="text" pInputText placeholder="Global Search" [(ngModel)]="filterValue"
                (ngModelChange)="filterInfoScreenTable()" [ngModelOptions]="{standalone: true}" />
            </span>
          </div>
        </div>

      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="id">Template ID
            <p-sortIcon field="id"></p-sortIcon>
          </th>
          <th pSortableColumn="last_modified">Last Modified
            <p-sortIcon field="last_modified"></p-sortIcon>
          </th>
          <th pSortableColumn="name">Name
            <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="step_id">Assigned Step ID
            <p-sortIcon field="step_id"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-template>
        <tr [pSelectableRow]="template" (click)="templateSelected(template, 'landing-page')">
          <td>{{template.id}}</td>
          <td> Last modified <br> {{template.last_modified | date:'medium'}}</td>
          <td>{{template.name}}</td>
          <td>{{template.step_id}}</td>
        </tr>

      </ng-template>
    </p-table>
    <p-divider></p-divider>


  </div>
  <app-sms-template-add-edit [trialId]="trialId" [selectedTemplate]="selectedSMSTemplate"
    [templateId]="form.value.templateId" [templateType]="form.controls.type.value"
    (TemplateSaveComplete)="templateSaveComplete()" *ngIf="form.controls.templateId.value"
    #smsTemplateComponent></app-sms-template-add-edit>

</form>
