export const environment = {
  production: false,
  apiPrefix: 'https://api-dev.vet-ai.com',
  apiConfigPrefix: 'https://config-dev.vet-ai.com',
  authPrefix: 'https://authentication-dev.vet-ai.com',
  messagesPrefix: 'wss://messages-dev.vet-ai.com',
  mediaPrefix: 'https://media-dev.vet-ai.com',
  client_id: '428534bd7d469b30',
  NG_BUILD_DIFFERENTIAL_FULL: 1,
  client_secret: '175bcc796d55813245732d62e009670eb6907f79123f55fa9413cf8c4421b184',
  pmsUrl: 'https://pms-dev.vet-ai.com/',
  cmsUrl: 'https://cms-dev.vet-ai.com/'
}
