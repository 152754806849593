<div class="stepEditComponent">
  <form [formGroup]="form" (submit)="UpdateOrSave()" class="stepForm">
    <div class="stepFormTopRow">

      <div class="p-field">
        <label for="stepName">*Step Name</label>
        <input [required]="true"
          id="stepName"
          type="text"
          formControlName="name"
          pInputText
          class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
          data-automationid="tms-create-stepName-input"
        >
        <small *ngIf="form?.get('name')?.invalid && form?.get('name')?.touched">Step name is required</small>
      </div>
      <p-button
        styleClass="p-button-danger"
        label="Delete"
        icon="pi pi-trash"
        data-automationId="tms-delete-step-button"
        (onClick)="deleteStep()"
        *ngIf="config?.data.id">
      </p-button>
      <div class="buttonGroup" *ngIf="!config?.data.id">
        <p-button
          [disabled]="form.invalid"
          styleClass="p-button-primary"
          label="Save"
          icon="pi pi-save"
          type="submit"
          data-automationid="tms-create-step-save-button"
        ></p-button>
        <p-button
          styleClass="p-button-danger"
          label="Cancel"
          icon="pi pi-times-circle"
          (click)="cancel()"
          data-automationid="tms-create-step-cancel-button"
        ></p-button>
      </div>
    </div>

    <div class="formRow">
      <div class="p-field">
        <label for="stepNumber">Step Number</label>
        <input
          [required]="true"
          id="stepNumber"
          type="text"
          formControlName="step_number"
          pInputText
          readonly="readonly"
          class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
        >
      </div>

      <div class="p-field">
        <label for="stepID">Step ID</label>
        <input
          id="id"
          type="text"
          formControlName="id"
          pInputText
          readonly="readonly"
          class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
        >
      </div>
    </div>

    <div class="formRow">
      <div class="p-field">
        <label for="stepType">*Step Type</label>
        <p-dropdown appendTo="body"
          [options]="stepTypes"
          placeholder="Select a step type"
          [required]="true"
          id="step_type"
          formControlName="step_type"></p-dropdown>
      </div>
    </div>
    <p-divider></p-divider>
    <h2 class="modalSectionHeader">Step Details</h2>
    <div class="formRow">
      <div class="p-field">
        <label for="duration">*Step Duration</label>
        <input type="number" pInputText
               [required]="true"
               id="duration"
               formControlName="duration"
               data-automationid="tms-create-stepDuration-input"
               (onChange)="validateDetails()"
        />
        <small *ngIf="form?.get('duration')?.invalid && form?.get('duration')?.touched">Step Duration is required</small>
      </div>

      <div class="p-field">
        <label for="duration_unit_id">*Duration Unit</label>
        <p-dropdown appendTo="body"
                    placeholder="Select Unit"
                    [required]="true"
                    id="duration_unit_id"
                    [options]="units"
                    optionLabel="plural"
                    optionValue="id"
                    formControlName="duration_unit_id"
                    data-automationid="tms-create-stepDurationUnit-dropdown"
                    (onChange)="validateDetails()"
        ></p-dropdown>
        <small *ngIf="form?.get('duration_unit_id')?.invalid && form?.get('duration_unit_id')?.touched">Duration Unit is required</small>
      </div>
    </div>

    <div class="formRow">
      <div class="p-field">
        <label for="visibility">*Step Visibility</label>
        <input type="number" pInputText
               [required]="true"
               id="visibility"
               formControlName="visibility"
               data-automationid="tms-create-stepVisibility-input"
               (onChange)="validateDetails()"
        />
        <small *ngIf="form?.get('visibility')?.invalid && form?.get('visibility')?.touched">Step Visibility is required</small>
      </div>

      <div class="p-field">
        <label for="visibility_unit_id">*Visibility Unit</label>
        <p-dropdown appendTo="body"
                    placeholder="Select Unit"
                    [required]="true"
                    id="visibility_unit_id"
                    [options]="units"
                    optionLabel="plural"
                    optionValue="id"
                    formControlName="visibility_unit_id"
                    data-automationid="tms-create-stepVisibilityUnit-dropdown"
                    (onChange)="validateDetails()"
        ></p-dropdown>
        <small *ngIf="form?.get('visibility_unit_id')?.invalid && form?.get('visibility_unit_id')?.touched">Visibility Unit is required</small>
      </div>
    </div>

    <div class="formRow" >
     <p-message *ngIf="!stepDetailsValid" severity="error" text="Step Visibility cannot be greater than Step Duration"></p-message>
    </div>

    <div class="formRow">
      <div class="p-field">
        <label for="algorithm">Algorithm</label>
        <p-dropdown id="algorithm"
                    placeholder="Algorithm"
                    appendTo="body"
                    [filter]="true"
                    filterBy="id"
                    formControlName="algorithm_id"
                    [options]="algorithms"
                    optionValue="doc_id"
                    optionLabel="label"
                    data-automationid="tms-create-stepAlgorithm-dropdown"></p-dropdown>
      </div>
    </div>
    <div class="p-field">
      <label for="important">Important</label>
      <p-checkbox id="important"
                  formControlName="important"
                  [binary]="true"
                  data-automationid="tms-create-stepImportant-check"
      ></p-checkbox>
    </div>
    <div class="p-field" *ngIf="form?.get('step_type')!.value === 'Internal Step'">
      <label for="showintimeline">Show In Timeline</label>
      <p-checkbox id="showintimeline"
                  formControlName="show_in_timeline"
                  [binary]="true"
                  data-automationid="tms-create-showInTimeline-check"
      ></p-checkbox>
        <small *ngIf="form?.get('show_in_timeline')?.invalid && form?.get('show_in_timeline')?.touched">Show in timeline ia required</small>
    </div>

    <p-divider></p-divider>
    <h2 class="modalSectionHeader">Rules</h2>
    <div *ngIf="stepRulesArray.controls.length > 0" formArrayName="comms">
      <div *ngFor="let stepRule of stepRulesArray.controls; let stepIndex = index;" [formGroupName]="stepIndex">
        <h3>Communication {{stepIndex + 1}}</h3>
        <p-divider *ngIf="stepIndex > 0"></p-divider>
        <div class="formRow">
          <div class="p-field">
            <label>Type</label>
            <p-dropdown placeholder="Type"
                        appendTo="body"
                        formControlName="type"
                        [options]="ruleCommTypes"
                        (onChange)="handleCommTypeChange($event, stepIndex)"></p-dropdown>
          </div>
          <div class="p-field">
            <label>Template</label>
            <div *ngIf="stepRule.value.type === commsTypeEnum.LandingPage">
              <p-dropdown placeholder="Template"
                          appendTo="body"
                          formControlName="notification_template_id"
                          optionLabel="name"
                          optionValue="id"
                          [filter]="true"
                          [options]="availableLandingPages"></p-dropdown>
            </div>
            <div *ngIf="stepRule.value.type === commsTypeEnum.SMS">
              <p-dropdown placeholder="Template"
                          appendTo="body"
                          formControlName="sms_template_id"
                          optionLabel="title"
                          optionValue="id"
                          [filter]="true"
                          filterBy="title"
                          [options]="availableSMSTemplates"></p-dropdown>
            </div>
            <div *ngIf="stepRule.value.type === commsTypeEnum.Email">
              <p-dropdown placeholder="Template"
                          appendTo="body"
                          formControlName="email_template_id"
                          optionLabel="name"
                          optionValue="id"
                          [filter]="true"
                          filterBy="name"
                          [options]="availableEmailTemplates"></p-dropdown>
            </div>
          </div>
          <div class="p-field">
            <label>Event Topic</label>
            <p-dropdown placeholder="Event Topic"
                        appendTo="body"
                        formControlName="event_topic"
                        optionLabel="name"
                        optionValue="topic"
                        [options]="availableEventTopics"></p-dropdown>
          </div>
        </div>
        <div class="formRow">
          <div class="p-field">
            <label>Rule</label>
            <p-dropdown placeholder="Rule"
                        appendTo="body"
                        optionLabel="name"
                        optionValue="id"
                        formControlName="rule_id"
                        [filter]="true"
                        filterBy="name"
                        [options]="availableRules"></p-dropdown>
          </div>
          <div class="p-field">
            <label>Delay (by hours)</label>
            <input type="number"
                   pInputText
                   min="0"
                   formControlName="delay" />
          </div>
          <div class="p-field rule-remove">
            <p-button
              label="Remove"
              icon="pi pi-trash"
              styleClass="p-button-danger"
              data-automationId="tms-step-rule-remove-button"
              (onClick)="removeStepRule(stepIndex)">
            </p-button>
          </div>
        </div>
      </div>
    </div>
    <p-button
      label="Add New Rule"
      icon="pi pi-plus"
      iconPos="right"
      data-automationId="tms-step-rule-add-button"
      (onClick)="addStepRule()">
    </p-button>
    <p-divider></p-divider>
    <p-button styleClass="p-button-link"
              [disabled]="form.invalid"
              type="submit"
              label="Save"
              data-automationid="tms-edit-step-save-button"
              icon="pi pi-check"
              class="saveButton"
              *ngIf="config?.data.id"
    ></p-button>
  </form>
</div>
