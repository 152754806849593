import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
// Ng Prime
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {HttpClientModule} from '@angular/common/http';
import {ToastModule} from 'primeng/toast';
import {MainHeaderComponent} from './components/main-header/main-header.component';
import {RouterModule} from '@angular/router';
import {TagModule} from 'primeng/tag';
import {ToolbarModule} from 'primeng/toolbar';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {DropdownModule} from 'primeng/dropdown';
import {CalendarModule} from 'primeng/calendar';
import {SkeletonModule} from 'primeng/skeleton';
import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {DividerModule} from 'primeng/divider';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {
  TrialStepsOverviewComponent,
} from '../../trial-details/components/trial-steps-overview/trial-steps-overview.component';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InputNumberModule} from 'primeng/inputnumber';
import {CheckboxModule} from 'primeng/checkbox';
import {TrialHeadingComponent} from './components/trial-heading/trial-heading.component';
import {StepAddEditComponent} from '../../trial-details/components/step-add-edit/step-add-edit.component';
import {RadioButtonModule} from 'primeng/radiobutton';
import {AgePipe} from './pipes/age-pipe.pipe';
import {
  StepStatusOverlayComponent,
} from '../../trial-details/components/step-status-overlay/step-status-overlay.component';
import {StatusTagComponent} from './components/status-tag/status-tag.component';
import { JoiiCurrencyPipe } from './pipes/joii-currency.pipe';
import { TypeOfPipe } from './pipes/type-of.pipe';
import { MessageModule } from 'primeng/message';
import { ConvertToHoursFrom } from './pipes/convert-to-hours';


@NgModule({
  declarations: [
    MainHeaderComponent,
    TrialStepsOverviewComponent,
    TrialHeadingComponent,
    StepAddEditComponent,
    StepStatusOverlayComponent,
    StatusTagComponent,
    AgePipe,
    JoiiCurrencyPipe,
    TypeOfPipe,
    ConvertToHoursFrom,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    // NG Prime
    InputTextModule,
    ButtonModule,
    ToastModule,
    TagModule,
    ToolbarModule,
    BreadcrumbModule,
    InputTextareaModule,
    DropdownModule,
    CalendarModule,
    SkeletonModule,
    MenubarModule,
    MessagesModule,
    TableModule,
    TabViewModule,
    DividerModule,
    OverlayPanelModule,
    DynamicDialogModule,
    FormsModule,
    ReactiveFormsModule,
    InputNumberModule,
    CheckboxModule,
    RadioButtonModule,
    MessageModule,
  ],
  exports: [
    InputTextModule,
    ButtonModule,
    ToastModule,
    TagModule,
    ToolbarModule,
    BreadcrumbModule,
    InputTextareaModule,
    DropdownModule,
    CalendarModule,
    SkeletonModule,
    MainHeaderComponent,
    StatusTagComponent,
    MenubarModule,
    MessagesModule,
    TableModule,
    TabViewModule,
    DividerModule,
    OverlayPanelModule,
    DynamicDialogModule,
    OverlayPanelModule,
    TrialStepsOverviewComponent,
    StepStatusOverlayComponent,
    InputNumberModule,
    CheckboxModule,
    TrialHeadingComponent,
    FormsModule,
    ReactiveFormsModule,
    RadioButtonModule,
    AgePipe,
    JoiiCurrencyPipe,
    TypeOfPipe,
    ConvertToHoursFrom,
  ], providers: [
    AgePipe,
    JoiiCurrencyPipe,
    TypeOfPipe,
    ConvertToHoursFrom
  ],
})
export class GeneralSharedModule {
}
