<div class="taskDetailsHeader">
  <div>
    <ng-container *ngIf="!loading">
      <p-overlayPanel #op>
          <span>
            <h3>{{trialDetails?.name}}</h3>
            {{trialDetails?.description}}
          </span>
      </p-overlayPanel>
      <h2 data-automation="tms-trial-status-banner">{{trialDetails?.name}}
        <span class="pi pi-info-circle"
              (click)="op.toggle($event)"
              data-automationid="tms-trial-status-bannerInfo"></span>
      </h2>
    </ng-container>
    <ng-container *ngIf="loading">
      <p-skeleton class="skeletonTitle"></p-skeleton>
    </ng-container>
  </div>
  <div class="trialDetailsActions">
    <ng-container *ngIf="loading">
      <p-skeleton class="skeletonButton"></p-skeleton>
    </ng-container>
    <ng-container *ngIf="!loading">
      <p-overlayPanel #op2>
        <ng-template pTemplate="content">
          <p-table [value]="statuses" selectionMode="single" [rows]="6" responsiveLayout="scroll"
                   [(selection)]="selectedStatus" (onRowSelect)="onStatusChange($event, op2)">
            <ng-template pTemplate="body" let-rowData let-status>
              <tr [pSelectableRow]="rowData">
                <td>{{status | titlecase}}</td>
              </tr>
            </ng-template>
          </p-table>
        </ng-template>
      </p-overlayPanel>
      <p-button styleClass="p-button-warning"
                icon="pi pi-lock-open"
                iconPos="left" label="Draft"
                *ngIf="trialDetails?.status === 'draft'"
                (click)="op2.toggle($event)"></p-button>
      <p-button styleClass="p-button-primary"
                icon="pi pi-lock"
                iconPos="left" label="{{trialDetails?.status | titlecase}}"
                *ngIf="trialDetails?.status !== 'draft'"
                (click)="op2.toggle($event)"></p-button>

    </ng-container>
  </div>
</div>
