import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { MessageService } from 'primeng/api';
import { MediaManagerService } from 'src/app/shared/general-shared/services/media-manager.service';
import { ImageValidationInterface } from 'src/app/shared/general-shared/services/image-validation.service';
import { ImageManagerImageInterface } from 'src/app/shared/general-shared/models/interfaces/image-manager-image.interface';

@Component({
  selector: 'app-image-manager',
  templateUrl: './image-manager.component.html'
})
export class ImageManagerComponent implements OnInit {

  @Input() chosenImage?: string;
  @Input() validation?: ImageValidationInterface;
  @Output() updateImage: EventEmitter<any> = new EventEmitter<any>();
  availableImages!: Array<ImageManagerImageInterface>;
  displayedImages!: Array<ImageManagerImageInterface>;
  maxDisplayableImages!: number;
  galleryPage!: number;
  loadingImages!: boolean;
  displayModal!: boolean;

  constructor(private messageService: MessageService,
              private mediaManagerService: MediaManagerService) {
  }

  ngOnInit() {
  }

  setupGallery() {
    this.maxDisplayableImages = 8;
    this.galleryPage = 1;
    if (this.availableImages.length > this.maxDisplayableImages) {
      this.displayedImages = this.availableImages.slice(0, this.maxDisplayableImages);
    } else {
      this.displayedImages = this.availableImages;
    }
  }

  moveGalleryPage(direction: any) {
    let pageChange = this.galleryPage;
    switch (direction) {
      case 'left':
        pageChange--;
        this.displayedImages = this.availableImages.slice((this.galleryPage - 2) * this.maxDisplayableImages,
          pageChange * this.maxDisplayableImages);
        break;
      case 'right':
        pageChange++;
        this.displayedImages = this.availableImages.slice(this.galleryPage * this.maxDisplayableImages,
          pageChange * this.maxDisplayableImages);
        break;
    }
    if (this.validation) {
      this.validateImages();
    }
    this.galleryPage = pageChange;
  }

  selectGalleryImage(image: any) {
    if (!image.valid) {
      return;
    }
    this.updateImage.emit(image.src);
    this.displayModal = false;
  }

  openImageGallery() {
    this.loadingImages = true;
    this.displayModal = true;
    this.mediaManagerService.getAvailableImages().subscribe((data: any) => {
      this.availableImages = data.map((image: any) => {
        return {src: image, valid: true};
      });
      this.setupGallery();
      if (this.validation) {
        this.validateImages();
      } else {
        this.loadingImages = false;
      }
    }, data => {
      this.messageService.add({key: "dc", detail: data.error.cause ? data.error.cause : 'Something went wrong fetching these images', severity: 'error'});
    });
  }

  uploadImage(event: any) {
    const uploadedNewFile = event.target.files[0];
    this.validateImageSize(window.URL.createObjectURL(uploadedNewFile), this.validation!).then(() => {
      const formData = new FormData();
      if (uploadedNewFile) {
        formData.append('notification-image', uploadedNewFile, uploadedNewFile.name);
        this.mediaManagerService.uploadImage(formData).subscribe((data: any) => {
          this.updateImage.emit(data.notification_image_urls[0]);
        }, data => {
          this.messageService.add({key: "dc", detail: data.error.cause ? data.error.cause : 'Something went wrong uploading this image', severity: 'error'});
        });
      }
    }).catch(() => {
      this.messageService.add({key: "dc", detail: `${this.getValidationTooltip()}`, severity: 'error'});
    });
  }

  validateImages() {
    this.displayedImages.map((image) => {
      this.validateImageSize(image.src, this.validation!).then((x) => {
        image.width = x.width;
        image.height = x.height;
        image.valid = true;
      }).catch((x) => {
        image.width = x.width;
        image.height = x.height;
        image.valid = false;
      });
    });
  }

  async validateImageSize(src: any, validation: ImageValidationInterface): Promise<{src: string, width: number, height: number}> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        let isValid = true;
        if (validation && typeof (validation.minWidth) === 'number') {
          isValid = isValid && validation.minWidth <= img.width;
        }
        if (validation && typeof (validation.maxWidth) === 'number') {
          isValid = isValid && validation.maxWidth >= img.width;
        }
        if (validation && typeof (validation.minHeight) === 'number') {
          isValid = isValid && validation.minHeight <= img.height;
        }
        if (validation && typeof (validation.maxHeight) === 'number') {
          isValid = isValid && validation.maxHeight >= img.height;
        }
        if (validation && validation.square) {
          isValid = isValid && img.width === img.height;
        }
        isValid ? resolve(img) : reject(img);
      };
      img.onerror = reject;
      img.src = src;
    });
  }

  getValidationTooltip() {
    const defaultMessage = 'This image is unavailable for selection.';
    const minWidth = this.validation && typeof this.validation.minWidth === 'number' ?
      ` Width must be greater than ${this.validation.minWidth}px.` :
      '';
    const maxWidth = this.validation && typeof this.validation.maxWidth === 'number' ?
      ` Width must be less than ${this.validation.maxWidth}px.` :
      '';
    const minHeight = this.validation && typeof this.validation.minHeight === 'number' ?
      ` Height must be greater than ${this.validation.minHeight}px.` :
      '';
    const maxHeight = this.validation && typeof this.validation.maxHeight === 'number' ?
      ` Height must be less than ${this.validation.maxHeight}px.` :
      '';
    const square = this.validation && this.validation.square ?
      ` Image must be square.` :
      '';
    return `${defaultMessage}${minWidth}${maxWidth}${minHeight}${maxHeight}${square}`;
  }

}

