import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MessageService} from 'primeng/api';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Participant} from 'src/app/shared/general-shared/models/interfaces/participant.interface';
import {sendSMSFormInterface} from 'src/app/shared/general-shared/models/interfaces/sendSMS-form.interface';
import {SmsTemplateInterface} from 'src/app/shared/general-shared/models/interfaces/smsTemplate.interface';
import {ErrorHandlerService} from 'src/app/shared/general-shared/services/error-handler.service';
import {ParticipantApiService} from 'src/app/shared/general-shared/services/participantApi.service';
import {SmsTemplateApiService} from 'src/app/shared/general-shared/services/smsTemplateApi.service';

interface dropdownTransform {
  code: number | string | null | undefined;
  name: string | null | undefined;
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-send-sms-template-modal',
  templateUrl: './send-sms-template-modal.component.html',
  styleUrls: ['./send-sms-template-modal.component.scss'],
})
export class SendSMSTemplateModalComponent implements OnInit {

  trialID!: number;

  availableTemplates: dropdownTransform[] = [];
  availableParticipants: dropdownTransform[] = [];

  form!: FormGroup<sendSMSFormInterface>;

  constructor(
    private readonly participantApiService: ParticipantApiService,
    private readonly smsTemplateApiService: SmsTemplateApiService,
    private readonly config: DynamicDialogConfig,
    private readonly ref: DynamicDialogRef,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly messageService: MessageService,
  ) {
    this.trialID = this.config.data.trialID;
  }

  ngOnInit() {
    this.createForm();
    this.getAvailableTemplates();
    this.getAvailableParticipants();
  }

  createForm() {
    this.form = new FormGroup<sendSMSFormInterface>({
      templateID: new FormControl<number>(0, [Validators.required, Validators.min(1)]),
      petID: new FormControl<number>(0, [Validators.required, Validators.min(1)]),
    });
  }

  private getAvailableTemplates() {
    this.smsTemplateApiService.getAllTemplatesForTrial(this.trialID).subscribe({
      next: (response) => {
        this.availableTemplates = response.map((template: SmsTemplateInterface): dropdownTransform => {
          return {
            code: template.id,
            name: template.title,
          };
        });
      },
      error: (error) => {
        this.errorHandlerService.handleError(error);
      },
    });
  }

  private getAvailableParticipants() {
    this.participantApiService.getAllParticipants(this.trialID).subscribe({
      next: (response) => {
        this.availableParticipants = response.map((template: Participant): dropdownTransform => {
          return {
            code: template.pet.id,
            name: `${template.user.name} (${template.user.email})`,
          };
        });
      },
      error: (error) => {
        this.errorHandlerService.handleError(error);
      },
    });
  }

  sendSMS() {
    this.participantApiService.sendSMSTemplateToParticipant(this.trialID, this.form.get('petID')!.value!, this.form.get('templateID')!.value!).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Sent SMS!',
          detail: 'Successfully sent an SMS template to the participant',
        });
        this.ref.close(true);
      },
      error: (error) => {
        this.errorHandlerService.handleError(error.error);
      },
    });
  }
}
