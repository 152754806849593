import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-status-tag',
  templateUrl: './status-tag.component.html',
})
export class StatusTagComponent implements OnInit {
  @Input()
  status!: string;
  tagClass!: string;
  showIcon: boolean = false;
  icon?: string;

  ngOnInit(): void {
    switch (this.status) {
      case 'Upcoming':
        this.tagClass = 'upcoming';
        this.showIcon = false;
        return;
      case 'In progress':
        this.tagClass = 'inProgress';
        this.showIcon = false;
        return;
      case 'Complete':
        this.tagClass = 'complete';
        this.showIcon = false;
        return;
      case 'Skipped':
        this.tagClass = 'skipped';
        this.showIcon = false;
        return;
      case 'Missed':
        this.tagClass = 'missed';
        this.showIcon = true;
        this.icon = 'pi pi-exclamation-circle';
        return;
      case 'Failed':
        this.tagClass = 'failed';
        this.showIcon = true;
        this.icon = 'pi pi-exclamation-circle';
        return;
      case 'Active':
        this.tagClass = 'active';
        this.showIcon = false;
        return;
      case 'Action Required':
        this.tagClass = 'actionRequired';
        this.showIcon = true;
        this.icon = 'pi pi-exclamation-circle';
        return;
      case 'Removed':
        this.tagClass = 'removed';
        this.showIcon = false;
        return;
    }

  }
}
