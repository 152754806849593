<p-table [value]="steps"
         selectionMode="single"
         *ngIf="!addingStep"
         (onRowReorder)="onReorderSteps($event)"
         #stepTable
         [paginator]="true"
         [rows]="20"
         [rowsPerPageOptions]="[10, 20, 50, 100]">
  <ng-template pTemplate="caption">
    <div class="stepTableHeader">
      <div>Steps</div>
      <div class="stepTableActions">
        <div *ngIf="activeReordering" class="reorderButtons">
          <p-button label="Save" icon="pi pi-save" iconPos="left" (click)="saveReorder()" styleClass="p-button-secondary"></p-button>
          <p-button label="Cancel" icon="pi pi-times" iconPos="left" (click)="cancelReorder()" styleClass="p-button-warning"></p-button>
        </div>
        <p-button label="Hide Internal Steps" (click)="hideInternalSteps()" [icon]="hidingInternal ? 'pi pi-eye-slash' : 'pi pi-eye'" iconPos="left" styleClass="p-button-text hideInternal"></p-button>
        <p-button label="Add Step" icon="pi pi-plus" iconPos="left" (click)="AddStep()"
                  [disabled]="!editEnabled" data-automationid="tms-step-addStep-button"></p-button>
        <span class="p-input-icon-left">
          <i class="pi pi-filter"></i>
        <p-dropdown placeholder="Filter Column"
                    [options]="['ID', 'Duration', 'Visibility', 'Important']"
                    [(ngModel)]="filterColumn"
                    (ngModelChange)="filterStepTable()"
                    styleClass="w-100"
                    data-automationid="tms-step-filterColumn-dropdown">
        </p-dropdown>
        </span>
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input type="text" pInputText
                 placeholder="Global Search"
                 [(ngModel)]="filterValue"
                 (ngModelChange)="filterStepTable()"
                 data-automationid="tms-step-filterValue-input"
          />
        </span>
      </div>

    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th *ngIf="editEnabled"></th>
      <th></th>

      <th data-automationid="tms-step-table-name-column">Step Name
      </th>
      <th>ID
      </th>
      <th>Step Type</th>
      <th pSortableColumn="duration" data-automationid="tms-step-table-duration-column">Duration
      </th>
      <th pSortableColumn="visibility" data-automationid="tms-step-table-visibility-column">Visibility
      </th>
      <th data-automationid="tms-step-table-important-column">Important / Unimportant
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-step let-index="rowIndex">
    <tr [pSelectableRow]="step" (click)="editStep(step, $event)" [pReorderableRow]="index" [pSelectableRowDisabled]="!editEnabled">
      <td *ngIf="editEnabled" pReorderableRowHandle>
        <span class="pi pi-ellipsis-v" pReorderableRowHandle></span>
      </td>
      <td>{{step.step_number}}</td>
      <td data-automationid="tms-step-table-name-item">{{step.name}}</td>
      <td data-automationid="tms-step-table-id-item">ID-{{step.id}}</td>
      <td class="stepType">
        <span>{{step.step_type}}</span>
      </td>
      <td data-automationid="tms-step-table-duration-item">{{step.duration}} {{step.duration_unit_name}}</td>
      <td data-automationid="tms-step-table-visibility-item">{{step.visibility}} {{step.visibility_unit_name}}</td>
      <td data-automationid="tms-step-table-important-item">{{step.important ? 'Important' : 'Unimportant'}}</td>
    </tr>

  </ng-template>
</p-table>

<app-step-details *ngIf="addingStep" (addComplete)="stepAdded()" [noOfSteps]="steps.length" [trialId]="trialId"></app-step-details>
