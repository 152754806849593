<article
  cdkDropList
  class="notification-wrapper"
  id="drop-list"
  #notificationBuilderPreview
  (cdkDropListDropped)="dropModule($event)">
  <p *ngIf="notificationContent.length === 0" class="warning-text">
    Please drop notification elements here
  </p>
  <div
    cdkDrag
    *ngFor="let module of notificationContent; let i = index;"
    class="module {{module.type}}"
    (click)="editModule(module, i)">
    <span
      *ngIf="module.type !== notificationDataTypes[2].type &&
      module.type !== notificationDataTypes[3].type &&
      module.type !== notificationDataTypes[4].type &&
      module.type !== notificationDataTypes[5].type &&
      module.type !== notificationDataTypes[6].type &&
      module.type !== notificationDataTypes[7].type">
      {{module.content}}
    </span>
    <span *ngIf="module.type === notificationDataTypes[2].type">
      <img alt="image content" [src]="module.content" />
    </span>
    <span *ngIf="module.type === notificationDataTypes[3].type && module.content === notificationDataTypes[3].content">
      <img alt="image content" [src]="module.content" />
    </span>
    <span *ngIf="module.type === notificationDataTypes[3].type && module.content !== notificationDataTypes[3].content">
      <span class="video-cover"></span>
      <iframe
        *ngIf="module.tempData.sanitizedURL"
        [src]="module.tempData.sanitizedURL"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </span>
    <span *ngIf="module.type === notificationDataTypes[4].type">
      <button pButton
        class="notification-btn"
        icon="pi pi-angle-right"
        iconPos="right"
        [class.notification-btn-dismiss]="module.content.type === 'dismiss'"
        [label]="module.content.label"></button>
    </span>
    <div *ngIf="module.type === notificationDataTypes[5].type">
      <div class="grid module-product-holder">
        <div class="col-6 left text-center">
          <span *ngIf="module.content?.featured_image"><img alt="product image" [src]="module.content?.featured_image" /></span>
          <p class="price">{{module.content?.price.price | joiiCurrency}}</p>
        </div>
        <div class="col-6 right">
          <h3>{{module.content?.name}}</h3>
          <p>
            {{(module?.tempData?.hasProductTextOverride ? module.custom_summary : module.content?.description) | slice: 0:130}}
            <span
              *ngIf="(module?.tempData?.hasProductTextOverride ? module?.custom_summary?.length || 0 : module?.content?.description?.length) > 129">
                ...
              </span>
          </p>
        </div>
      </div>
    </div>
    <div *ngIf="module.type === notificationDataTypes[6].type">
      <hr>
    </div>
    <span *ngIf="module.type === notificationDataTypes[7].type && module.content === notificationDataTypes[7].content">
      <img alt="image content" [src]="module.content" />
    </span>
    <div *ngIf="module.type === notificationDataTypes[7].type && module.content !== notificationDataTypes[7].content">
      <div class="carousel-preview">
        <div
          class="carousel-preview-slider"
          [style.left.px]="-(module?.tempData?.selectedCarouselOption?.index || 0) * notificationBuilderWidth"
          [style.width.px]="notificationBuilderWidth * module.content.length">
        <div class="carousel-item" *ngFor="let content of module.content" [style.width.px]="notificationBuilderWidth">
          <img alt="carousel image" [src]="content?.image_url || 'assets/placeholders/image-placeholder.png'">
          <p class="carousel-text">{{content?.message}}</p>
        </div>
        </div>
      </div>
      <div class="carousel-dots">
        <span
          *ngFor="let dot of module.content; let i = index"
          [class.selected]="dot === module?.tempData?.selectedCarouselOption"></span>
      </div>
    </div>
  </div>
</article>
