// Angular / RXJS
import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
/**
 * ApiService
 */
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  /**
   * @param http - Angular HTTP Client to handle API requests.
   */
  constructor(private http: HttpClient) { }
  /**
   * getData function - Calls http.get (with endpoint param) and gets appropriate headers from getHeaders call
   * @param: (String) endpoint
   */
  getData<T = any>(endpoint: string, params?: {[p: string]: string | string[]}): Observable<T> {
    let headers;
    if (params) {
      headers = {params} as any;
    }
    return this.http.get(endpoint, headers) as unknown as Observable<T>;
  }
  /**
   * postData function - Calls http.post (with endpoint and data params) and gets appropriate headers
   * from getHeaders calls (based on isSimple param)
   * @param: (String) endpoint
   * @param: (Object) data
   * @param: (String) isSimple
   */
  postData<Payload = any, Return = any>(endpoint: string, data: Payload): Observable<Return> {
    return this.http.post(endpoint, data) as unknown as Observable<Return>;
  }
  /**
   * putData function - Calls http.put (with endpoint and data params) and gets appropriate headers
   * from getHeaders calls (based on isSimple param)
   * @param: (String) endpoint
   * @param: (Object) data
   * @param: (String) isSimple
   */
  putData<Payload = any, Return = any>(endpoint: string, data: Payload): Observable<Return> {
    return this.http.put(endpoint, data) as unknown as Observable<Return>;
  }
  /**
   * patchData function - Calls http.patch (with endpoint and data params) and gets appropriate headers
   * from getHeaders calls (based on isSimple param)
   * @param: (String) endpoint
   * @param: (Object) data
   * @param: (String) isSimple
   */
  patchData<Payload = any, Return = any>(endpoint: string, data: Payload): Observable<Return> {
    return this.http.patch(endpoint, data) as unknown as Observable<Return>
  }
  /**
   * deleteData function - Calls http.delete (with endpoint params) and gets appropriate headers
   * from getHeaders calls (based on isSimple param)
   * @param: (String) endpoint
   * @param: (String) isSimple
   */
  deleteData<T = any>(endpoint: string): Observable<T> {
    return this.http.delete(endpoint) as unknown as Observable<T>;
  }
}
