<section class="image-manager-holder">
  <div class="chosen-img-holder">
    <img *ngIf="chosenImage" [src]="chosenImage" class="chosen-img" />
  </div>
  <div class="btn-holder">
    <p-button
      label="Pick Image"
      styleClass="p-button-sm"
      icon="pi pi-image"
      iconPos="right"
      (onClick)="openImageGallery()"></p-button>
    <p-button
      label="Upload Image"
      styleClass="p-button-sm p-button-secondary"
      icon="pi pi-cloud-upload"
      iconPos="right"
      (onClick)="fileInput.click()"></p-button>
    <input
      type="file"
      hidden
      #fileInput
      (change)="uploadImage($event)" />
  </div>
</section>
<p-dialog
  [(visible)]="displayModal"
  [style]="{width: '80vw', height: '80vh'}"
  [draggable]="false"
  [modal]="true">
  <section class="image-gallery gallery-holder">
    <article *ngIf="availableImages" class="grid">
      <div
        *ngFor="let image of displayedImages"
        class="col-3 gallery-column"
        [class.disabled]="!image.valid"
        (click)="selectGalleryImage(image)">
        <div
          class="image-holder"
          [class.chosen-image]="image.src === chosenImage">
          <img [src]="image.src" />
          <div class="overlay" *ngIf="!image.valid">
            <div class="overlay-info">
              <i class="pi pi-exclamation-triangle" [pTooltip]="getValidationTooltip()"></i>
              <p>{{image?.width}}px x {{image?.height}}px</p>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!displayedImages">
        <p>There are no images in this bucket</p>
      </div>
    </article>
    <article *ngIf="!availableImages" class="row">
      <div class="col-12 text-center loading-holder">
        <p-progressSpinner
          styleClass="w-4rem h-4rem"
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".5s"></p-progressSpinner>
        <p>Loading images...</p>
      </div>
    </article>
  </section>
  <ng-template pTemplate="footer">
    <footer
      class="col-12 text-center"
      *ngIf="availableImages">
      <nav *ngIf="availableImages.length > maxDisplayableImages">
        <p-button
          *ngIf="galleryPage > 1"
          styleClass="p-button-sm p-button-rounded p-button-secondary"
          icon="pi pi-chevron-left"
          (onClick)="moveGalleryPage('left')"></p-button>
        <span class="gallery-page-title">
          Page {{galleryPage}} of {{availableImages.length / maxDisplayableImages | number: '1.0-0'}}
        </span>
        <p-button
          *ngIf="(galleryPage * maxDisplayableImages) < availableImages.length"
          styleClass="p-button-sm p-button-rounded p-button-secondary"
          icon="pi pi-chevron-right"
          (onClick)="moveGalleryPage('right')"></p-button>
      </nav>
    </footer>
  </ng-template>
</p-dialog>
