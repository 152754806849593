<p-tabView>
  <p-tabPanel header="Personal Details">
    <form>
      <section>
        <div class="flex-row">
          <div class="item">
            <label htmlFor="participant_name">Name</label>

            <span class="p-input-icon-right">
              <input value="{{this.participant.pet_name}}" disabled="true" pInputText id="participant_name" type="text"
                data-automationid="tms-participantInfo-petName">
              <i pTooltip="Copy to clipboard" tooltipPosition="top" (click)="copyToClipboard(this.participant.pet_name)"
                class="pi pi-copy"></i>
            </span>
          </div>

        </div>
        <div class="flex-row">
          <div class="item">
            <label htmlFor="participant_insured">Insured?</label>

            <input value="{{this.insurance ? 'Yes' : 'No'}}" disabled="true" pInputText id="participant_insured"
              type="text" data-automationid="tms-participantInfo-insured">
          </div>

          <div class="item">
            <label htmlFor="participant_insurer">Insurer</label>

            <span class="p-input-icon-right">
              <input value="{{this.insurance}}" disabled="true" pInputText id="participant_insurer" type="text"
                data-automationid="tms-participantInfo-insurer">
              <i pTooltip="Copy to clipboard" tooltipPosition="top" (click)="copyToClipboard(this.insurance)"
                class="pi pi-copy"></i>
            </span>
          </div>
        </div>
      </section>

      <section>
        <div class="flex-row">
          <div class="item">
            <label for="breed">Breed</label>

            <span class="p-input-icon-right">
              <input value="{{this.breeds}}" disabled="true" id="breed" pInputText type="text"
                data-automationid="tms-participantInfo-breed">
              <i pTooltip="Copy to clipboard" tooltipPosition="top" (click)="copyToClipboard(this.breeds)"
                class="pi pi-copy"></i>
            </span>
          </div>

          <div class="item">
            <label for="species">Species</label>

            <span class="p-input-icon-right">
              <input value="{{this.species}}" disabled="true" id="species" pInputText type="text"
                data-automationid="tms-participantInfo-species">
              <i pTooltip="Copy to clipboard" tooltipPosition="top" (click)="copyToClipboard(this.species)"
                class="pi pi-copy"></i>
            </span>
          </div>
        </div>

        <div class="flex-row">
          <div class="item">
            <label for="age">Age</label>

            <input value="{{this.participant.pet_age | agePipe}}" disabled="true" id="age" pInputText type="text"
              data-automationid="tms-participantInfo-age">
          </div>

          <div class="item">
            <label for="sex">Sex</label>

            <span class="p-input-icon-right">
              <input value="{{this.participant.pet_gender}}" disabled="true" id="sex" pInputText type="text"
                data-automationid="tms-participantInfo-sex">
              <i pTooltip="Copy to clipboard" tooltipPosition="top"
                (click)="copyToClipboard(this.participant.pet_gender)" class="pi pi-copy"></i>
            </span>
          </div>
        </div>
      </section>
    </form>
    <hr>
    <form>
      <section>
        <div class="flex-row">
          <div class="item">
            <label for="owner_name">Owner Name</label>

            <span class="p-input-icon-right">
              <input value="{{this.participant.user_name}}" disabled="true" id="owner_name" pInputText type="text"
                data-automationid="tms-participantInfo-ownerName">
              <i pTooltip="Copy to clipboard" tooltipPosition="top"
                (click)="copyToClipboard(this.participant.user_name)" class="pi pi-copy"></i>
            </span>
          </div>
        </div>

        <div class="flex-row">
          <div class="item">
            <label for="owner_contact_number">Owner Contact Number</label>

            <span class="p-input-icon-right">
              <input value="{{this.participant.user_phone}}" disabled="true" id="owner_contact_number" pInputText
                type="text" data-automationid="tms-participantInfo-ownerNumber">
              <i pTooltip="Copy to clipboard" tooltipPosition="top"
                (click)="copyToClipboard(this.participant.user_phone)" class="pi pi-copy"></i>
            </span>
          </div>
        </div>

        <div class="flex-row">
          <div class="item">
            <label for="owner_email_address">Owner Email Address</label>

            <span class="p-input-icon-right">
              <input value="{{this.participant.user_email}}" disabled="true" id="owner_email_address" pInputText
                type="text" data-automationid="tms-participantInfo-ownerEmail">
              <i pTooltip="Copy to clipboard" tooltipPosition="top"
                (click)="copyToClipboard(this.participant.user_email)" class="pi pi-copy"></i>
            </span>
          </div>
        </div>
      </section>

      <section>
        <div class="flex-row">
          <div class="item">
            <label for="owner_address">Owner Address</label>

            <span class="p-input-icon-right">
              <textarea value="{{this.address}}" disabled="true" [autoResize]="true" id="owner_address" rows="10"
                pInputTextarea data-automationid="tms-participantInfo-ownerAddress"></textarea>
              <i pTooltip="Copy to clipboard" (click)="copyToClipboard(this.address)" tooltipPosition="top"
                class="pi pi-copy"></i>
            </span>
          </div>
        </div>
      </section>
    </form>
  </p-tabPanel>
  <p-tabPanel header="Vet Practice Details">
  </p-tabPanel>
</p-tabView>
