import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MenuItem } from "primeng/api";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  breadcrumbItemsSource = new BehaviorSubject(JSON.parse(localStorage.getItem("breadcrumbs") || "[]"));
  breadcrumbItems = this.breadcrumbItemsSource.asObservable();

  breadcrumbHome: MenuItem = { icon: 'pi pi-th-large', routerLink: '/' };

  constructor(private router: Router) {
    window.addEventListener('beforeunload', () => {
      this.clearbreadcrumbs();
    });
  }

  setBreadcrumb(items: MenuItem[]) {
    window.localStorage.setItem("breadcrumbs", JSON.stringify(items));
    this.breadcrumbItemsSource.next(items)
  }

  addBreadcrumb(item: MenuItem) {
    const menuUrl = this.generateUrlWithQueryParams(item)
    if (this.router.url !== menuUrl) return

    let currentBreadcrumbs = this.breadcrumbItemsSource.getValue()

    if (currentBreadcrumbs[currentBreadcrumbs.length - 1] === item) {
      return
    }
    this.setBreadcrumb([...currentBreadcrumbs, item])
  }

  removeBreadcrumb(itemID: string) {
    let currentBreadcrumbs = this.breadcrumbItemsSource.getValue() as MenuItem[]
    this.setBreadcrumb(currentBreadcrumbs.filter((i: MenuItem) => i.id !== itemID))
  }

  generateUrlWithQueryParams(model: MenuItem): string {
    const urlTree = this.router.createUrlTree([model.routerLink], {
      queryParams: model.queryParams,
    });

    return this.router.serializeUrl(urlTree);
  }

  clearbreadcrumbs() {
    this.setBreadcrumb([])
  }
}
