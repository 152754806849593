import {Component, OnInit} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormControl, FormGroup} from '@angular/forms';
import {
    ParticipantSearchFormInterface,
} from '../../../../shared/general-shared/models/interfaces/participant-search-form.interface';

@Component({
    selector: 'app-participant-advanced-search-modal',
    templateUrl: './participant-advanced-search-modal.component.html',
})
export class ParticipantAdvancedSearchModalComponent implements OnInit {

    form!: FormGroup<ParticipantSearchFormInterface>;


    constructor(private ref: DynamicDialogRef) {
    }

    search() {
        this.ref.close(this.form.getRawValue());
    }

    ngOnInit(): void {
        this.form = new FormGroup<ParticipantSearchFormInterface>({
            email: new FormControl(),
            ownerName: new FormControl(),
            petName: new FormControl(),
        });
    }
}
