import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class EmailTemplatesService {

  constructor(private apiService: ApiService) { }

  getAllEmailTemplates(): Observable<any> {
    return this.apiService.getData(environment.apiPrefix + '/v1/emails/templates');
  }
}
