import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { StepInterface } from '../../../shared/general-shared/models/interfaces/step.interface';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { StepAddEditComponent } from '../step-add-edit/step-add-edit.component';
import { StepApiService } from '../../../shared/general-shared/services/stepApi.service';
import { Table, TableColumnReorderEvent } from 'primeng/table';
import { ErrorHandlerService } from 'src/app/shared/general-shared/services/error-handler.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-trial-steps-overview',
  templateUrl: './trial-steps-overview.component.html',
})
export class TrialStepsOverviewComponent implements OnInit {
  originalSteps: StepInterface[] = [];
  steps: StepInterface[] = [];
  @Input() trialId: number = 0;
  @Input()
  editEnabled: boolean = true;
  @Output()
  navigationChange = new EventEmitter<string | undefined>();
  filterColumn: string = '';
  filterValue: string = '';
  @ViewChild('stepTable') stepTable!: Table;
  ref?: DynamicDialogRef;
  addingStep: boolean = false;
  activeReordering: boolean = false;

  hidingInternal: boolean = false;

  constructor(private readonly stepApiService: StepApiService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlerService,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.getSteps();
  }

  private getSteps() {
    this.stepApiService.GetTrialSteps(this.trialId).subscribe({
      next: (response: StepInterface[]) => {
        this.originalSteps = JSON.parse(JSON.stringify(response));
        this.steps = response;
      },
      error: (error) => {
        this.errorHandler.handleError(error.error);
      }
    });
  };

  filterStepTable() {
    this.stepTable.filters = {};
    this.stepTable.reset();
    if (this.filterValue.length === 0 || this.filterColumn.length === 0) {
      return;
    }
    switch (this.filterColumn) {
      case 'Duration':
        this.filterValue.split(' ').forEach((durationValue) => {
          if (!isNaN(Number(durationValue))) {
            this.stepTable.filter(durationValue, 'duration', 'contains');
          } else {
            this.stepTable.filter(durationValue, 'duration_unit_name', 'contains');
          }
        });
        break;
      case 'Visibility':
        this.filterValue.split(' ').forEach((visibilityValue) => {
          if (!isNaN(Number(visibilityValue))) {
            this.stepTable.filter(visibilityValue, 'visibility', 'contains');
          } else {
            this.stepTable.filter(visibilityValue, 'visibility_unit_name', 'contains');
          }
        });
        break;
      case 'Important':
        const isUnimportant = 'false'.includes(this.filterValue.toLowerCase())
          || this.filterValue.toLowerCase().startsWith('u');
        this.stepTable.filter(isUnimportant ? 'false' : 'true', 'important', 'equals');


        break;
      default:
        this.stepTable.filter(this.filterValue, this.filterColumn.toLowerCase(), 'contains');
    }
  }

  AddStep() {
    if (!this.editEnabled)
      return;
    this.addingStep = true;
    this.navigationChange.emit('Step View');
  }

  stepAdded() {
    this.addingStep = false;
    this.getSteps();
  }

  private openStepModal(step: any) {
    if (!this.editEnabled)
      return;
    this.ref = this.dialogService.open(StepAddEditComponent, {
      data: step,
      maximizable: true,
      closable: true,
      header: 'Add Step',
      width: '70%',
    });
    this.ref.onClose.subscribe((value) => {
      if (value) {
        this.getSteps();
      }
    });
  }

  editStep(step: StepInterface, event: any) {
    this.openStepModal(step);
  }

  onReorderSteps(event: TableColumnReorderEvent) {
    this.activeReordering = true;
  }

  saveReorder() {
    if (this.filterValue) {
      this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'Please clear the filter before reordering' });
      return;
    }

    let payload = this.steps.map((step, index) => {
      return {
        id: step.id!,
        step_number: index + 1,
        trial_id: this.trialId
      }
    })
    this.stepApiService.updateStepOrder(this.trialId, payload).subscribe({
      next: () => {
        this.getSteps();
        this.activeReordering = false;
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Steps reordered successfully' });
      },
      error: (err) => {
        this.errorHandler.handleError(err.error);
      }
    })
  }

  cancelReorder() {
    this.activeReordering = false;
    this.steps = JSON.parse(JSON.stringify(this.originalSteps));
  }

  hideInternalSteps() {
    if (!this.hidingInternal) {
      this.steps = this.steps.filter((step) => step.step_type != "Internal Step" ? true : false);
      this.hidingInternal = !this.hidingInternal;
      return;
    }

    this.steps = JSON.parse(JSON.stringify(this.originalSteps));
    this.hidingInternal = !this.hidingInternal;
  }
}
