import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { isNumber } from 'lodash';
import { NotificationBuilderService } from 'src/app/shared/general-shared/services/notification-builder.service';
import { CommService } from 'src/app/shared/general-shared/services/comm.service';

@Component({
  selector: 'app-notification-builder-edit',
  templateUrl: './notification-builder-edit.component.html'
})
export class NotificationBuilderEditComponent implements OnInit {

  @ViewChild('ctaProductsChips') ctaProductsChips!: ElementRef<HTMLInputElement>;
  @Input() editedModule: any;
  @Input() createEditForm: any;
  @Input() notificationDataTypes!: Array<any>;
  @Input() ctaChosenProducts!: Array<any>;
  @Input() showButtonExternalLink!: boolean;
  @Input() loadingAvailableProducts?: boolean;
  @Input() loadingAvailableAlgorithms!: boolean;
  @Input() loadingAvailableCTAProducts!: boolean;
  @Input() ctaChipsInput: any;
  @Input() videoNodeID: any;
  @Input() carouselValidation: any;
  @Input() editedModuleIndex!: number;

  @Input() filteredAlgorithms?: Array<any>;
  @Input() filteredProducts!: Array<any>;
  @Input() filteredCTAProducts?: Array<any>;
  @Input() availableAlgorithms!: Array<any>;
  @Input() availableCTAProducts!: Array<any>;
  @Input() availableProducts!: Array<any>;

  @Output() handleCTAChangeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeModuleEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() validateCarouselEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() validateProductEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  availableButtonOptions!: Array<any>;
  productSeparatorKeysCodes: number[] = [ENTER, COMMA];

  // silly problem, p-dropdown doesn't like to display "false" as a boolean
  webLinkOptions: any = [
    {
      label: 'true',
      value: true
    },
    {
      label: 'false',
      value: false
    }
  ]

  constructor(public sanitizer: DomSanitizer,
    private commService: CommService,
    private notificationBuilderService: NotificationBuilderService) { }

  ngOnInit() {
    this.getNotificationButtonTypes();
  }

  getNotificationButtonTypes() {
    this.availableButtonOptions = this.commService.getButtonOptions();
  }

  handleImageSelect(image: any) {
    this.editedModule.content = image;
  }

  addVideo(event: any) {
    const youTubeURL = 'https://www.youtube.com/embed/' + event;
    this.editedModule.tempData = {};
    this.editedModule.tempData.sanitizedURL = this.sanitizer.bypassSecurityTrustResourceUrl(youTubeURL);
    this.editedModule.content = youTubeURL;
  }

  removeVideo() {
    this.editedModule.tempData = {};
    this.editedModule.content = this.notificationDataTypes[3].content;
  }

  handleCTAChange(event: any) {
    this.handleCTAChangeEvent.emit(event);
  }

  filterAlgorithms(searchTerm: any) {
    this.filteredAlgorithms = this.notificationBuilderService.filterModuleContent(searchTerm.query,
      this.filteredAlgorithms,
      this.availableAlgorithms,
      'algorithms');
  }

  filterCTAProducts(searchTerm: any) {
    this.filteredCTAProducts = this.notificationBuilderService.filterModuleContent(searchTerm.query,
      this.filteredCTAProducts,
      this.availableCTAProducts,
      'products');
  }

  filterModuleProducts(searchTerm: any) {
    this.filteredProducts = this.notificationBuilderService.filterModuleContent(searchTerm.query,
      this.filteredProducts,
      this.availableProducts,
      'products');
  }

  removeProductFromChips(product: any) {
    const index = this.editedModule.content.product_ids.indexOf(product.id);
    const chosenIndex = this.ctaChosenProducts.indexOf(product);
    if (chosenIndex >= 0) {
      this.ctaChosenProducts.splice(chosenIndex, 1);
    }
    if (index >= 0) {
      this.editedModule.content.product_ids.splice(index, 1);
    }
  }

  addCTAProduct(product: any) {
    // If product doesn't exist in arrays then push
    if (this.ctaChosenProducts.findIndex(i => i.id === product.option.value.id) === -1) {
      this.ctaChosenProducts.push(product.option.value);
    }
    if (this.editedModule.content.product_ids.findIndex((i: any) => i === product.option.value.id) === -1) {
      this.editedModule.content.product_ids.push(product.option.value.id);
    }
  }

  addModuleProduct(product: any) {
    this.editedModule.content = { ...product.value };
    this.validateProductEvent.emit(isNumber(this.editedModule.content?.id));
  }

  switchCarouselOption(index: number) {
    this.editedModule.tempData.selectedCarouselOption = this.editedModule.content.find((x: any, i: any) => i === index);
  }

  addCarouselSlide() {
    this.editedModule = this.notificationBuilderService.addCarouselSlide(this.editedModule);
    this.validateCarouselEvent.emit();
  }

  handleCarouselImageSelect(event: any) {
    this.editedModule.tempData.selectedCarouselOption.image_url = event;
    this.validateCarouselEvent.emit();
  }

  onMessage() {
    this.validateCarouselEvent.emit();
  }

  removeSlide(event: any) {
    this.editedModule = this.notificationBuilderService.removeCarouselSlide(this.editedModule, event);
    this.validateCarouselEvent.emit();
  }

  removeModule(event: any) {
    this.removeModuleEvent.emit(event);
  }

}
