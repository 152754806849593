// Angular / RXJS
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
// Environment
import {environment} from "../../../../environments/environment";
import {StorageService} from "../services/storage.service";
/**
 * Auth Interceptor
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly storageService: StorageService
  ) {}
  /**
   * intercept function - Handles incoming HTTP request and attaches an auth token to the header.
   * @param request
   * @param next
   * @returns Observable<HttpEvent<any>>
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requiresToken = this.routeRequiresToken(request.url);
    if (requiresToken) {
      const token = this.getUserToken();
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return next.handle(request);
  }
  /**
   * getUserToken function - returns current userToken from local storage.
   */
  getUserToken(): string | undefined {
    return this.storageService.getData('userToken');
  }

  /**
   * routeRequiresToken - returns true when a route requires authentication.
   * @param url
   * @returns boolean
   */
  routeRequiresToken(url: string): boolean {
    let requiresToken = true;
    // If login route
    if (url.includes('login')) {
      requiresToken = false;
    }
    // If route doesn't contain the APIs route prefix
    if (!url.includes(environment.apiPrefix) && !url.includes(environment.mediaPrefix)) {
      requiresToken = false;
    }
    return requiresToken;
  }

}
