import { ApiService } from './api.service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Addresses } from '../models/interfaces/address.interface';

@Injectable({
  providedIn: 'root',
})
export class AddressApiService {
  constructor(private readonly apiService: ApiService) {
    this.apiService = apiService;
  }

  getUserAddressByID(userID: number): Observable<Addresses> {
    return this.apiService.getData<Addresses>(environment.apiPrefix + `/v1/addresses/users/${userID}`);
  }
}
