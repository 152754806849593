// Angular / RXJS
import { Injectable } from '@angular/core';
import {Router} from "@angular/router";
// Services
import {ApiService} from "./api.service";
import {StorageService} from "./storage.service";
// Environment
import {environment} from "../../../../environments/environment";
// Interfaces
import {LoginResultInterface} from "../../../auth/models/interfaces/login-result.interface";
import {LoginPayloadInterface} from "../../../auth/models/interfaces/login-payload.interface";
import {Observable} from "rxjs";
/**
 * Auth Service
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  /**
   * @param apiService
   * @param storageService
   * @param router
   */
  constructor(
    private readonly apiService: ApiService,
    private readonly storageService: StorageService,
    private readonly router: Router
  ) { }
  /**
   * login function - Calls the login endpoint with package details needs to auth user
   * @param details
   * @returns Observable<LoginResultInterface>
   */
  login(details: { username: string, password: string }): Observable<LoginResultInterface> {
    const loginPackage: LoginPayloadInterface = {
      grant_type: 'password',
      client_id: environment?.client_id,
      client_secret: environment?.client_secret,
      scope: 'admin',
      ...details
    };
    return this.apiService.postData<LoginPayloadInterface, LoginResultInterface>(environment.authPrefix + '/v1/login', loginPackage);
  }
  /**
   * logout function - Removes user and token from local storage then navigates back to login page.
   * @returns Promise<void>
   */
  async logout(): Promise<void> {
    this.storageService.removeData('currentUser');
    await this.router.navigate(
      ['login']
    );
  }
  /**
   * setUserLocal function - Sets currentUser in local storage from user param object
   * @param user - logged-in user object
   */
  setUserLocal(user: any): void {
    this.storageService.setData('currentUser', user);
  }

  /**
   * setUserToken function - Sets userToken in localStorage for authenticating API calls
   * @param token - JWT Token from Auth response
   */
  setUserToken(token: any): void {
    this.storageService.setData('userToken', token);
  }
}
