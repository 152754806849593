import {ApiService} from './api.service';
import {environment} from '../../../../environments/environment';
import {lastValueFrom, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {TrialInterface} from '../models/interfaces/trial.interface';

@Injectable({
  providedIn: 'root',
})
export class TrialApiService {
  constructor(private readonly apiService: ApiService) {
    this.apiService = apiService;
  }

  GetAllTrials(): Observable<TrialInterface[]> {
    return this.apiService.getData<TrialInterface[]>(environment.apiPrefix + '/v1/trials');
  }

  async CreateNewTrial(trial: any): Promise<number> {
    const response = await lastValueFrom(this.apiService.postData<TrialInterface, TrialInterface>(environment.apiPrefix + '/v1/trials', trial));
    return response.id;
  }

  async UpdateTrial(trial: any) {
    const response = await lastValueFrom(this.apiService.putData<TrialInterface, TrialInterface>(environment.apiPrefix + '/v1/trials/' + trial.id, trial));
    return response.id;
  }

  GetTrial(id: number): Observable<TrialInterface> {
    return this.apiService.getData<TrialInterface>(environment.apiPrefix + '/v1/trials/' + id);
  }

  async DeleteTrial(trialId: number) {
    await lastValueFrom(this.apiService.deleteData(`${environment.apiPrefix}/v1/trials/${trialId}`));
  }
}
