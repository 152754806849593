import {Component, OnInit} from '@angular/core';
import {MessageService} from 'primeng/api';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ErrorHandlerService} from 'src/app/shared/general-shared/services/error-handler.service';
import {ParticipantApiService} from 'src/app/shared/general-shared/services/participantApi.service';
import {StepApiService} from '../../../shared/general-shared/services/stepApi.service';
import {ResolverInterface} from '../../../shared/general-shared/models/interfaces/resolver.interface';

@Component({
  selector: 'app-remove-participant-modal',
  templateUrl: './remove-participant-modal.component.html',
  styleUrls: ['./remove-participant-modal.component.scss'],
})
export class RemoveParticipantModalComponent implements OnInit {
  participantID!: number;
  trialID!: number;
  selectedReason!: number;
  otherReason: string = '';
  maxAllowsChars = 500;
  numberOfChars: number = 0;
  otherReasonId: number = 6;

  removalReasons?: ResolverInterface[];

  constructor(
    private participantApiService: ParticipantApiService,
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private readonly errorHandlerService: ErrorHandlerService,
    private messageService: MessageService,
    private stepApiService: StepApiService,
  ) {
  }

  ngOnInit() {
    this.trialID = this.config.data.trialID;
    this.participantID = this.config.data.participantID;
    this.stepApiService.getRemovalReasons().subscribe(reasons => {
      this.removalReasons = reasons;
    });
  }

  removeParticipant() {
    if (this.selectedReason === this.otherReasonId) {
      if (this.numberOfChars > this.maxAllowsChars || this.numberOfChars <= 0)
        return this.errorHandlerService.handleError('Malformed reason. Either too long or no reason given');
    }

    this.participantApiService.removeParticipant(this.trialID, this.participantID, this.selectedReason, this.otherReason).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Removed!',
          detail: 'Successfully removed participant from trial',
        });
        this.ref.close(true);
      },
      error: error => {
        this.errorHandlerService.handleError(error);
      },
    });
  }

  updateNumOfChars(currentCharLength: number) {
    this.numberOfChars = currentCharLength;
  }
}
