// Angular / RXJS
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Guards
import {AuthGuard} from './shared/general-shared/guards/auth.guard';
// Routes
const routes: Routes = [
  {
    title: 'Dashboard',
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    title: 'Login',
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    title: 'Dashboard',
    path: 'dashboard',
    loadChildren: () => import('./trials-dashboard/trials-dashboard.module').then(m => m.TrialsDashboardModule),
    canActivate: [AuthGuard],
  },
  {
    title: 'Trial',
    path: 'trial',
    loadChildren: () => import('./trial-details/trial-details.module').then(m => m.TrialDetailsModule),
    canActivate: [AuthGuard],
  },
];

/**
 * AppRoutingModule
 */
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
