import {
  Component,
  OnInit,
  OnDestroy, Input, EventEmitter, Output, ChangeDetectorRef
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';
// Services
import { ProductsService } from '../../../shared/general-shared/services/products.service';
import { ErrorHandlerService } from 'src/app/shared/general-shared/services/error-handler.service';
import { MessageService } from 'primeng/api';
import { NotificationBuilderService } from '../../../shared/general-shared/services/notification-builder.service';
import { ImageValidationService } from '../../../shared/general-shared/services/image-validation.service';
import { DecisionTreesService } from 'src/app/shared/general-shared/services/decision-trees.service';
// Models/Interfaces
import { NotificationDataTypeModel } from '../../../shared/general-shared/models/nofifications.model';
import { InfoScreenModel } from '../../../shared/general-shared/models/info-screens.model';
import { ImageValidationInterface } from '../../../shared/general-shared/models/interfaces/image-validation.interface';
import { CommService } from 'src/app/shared/general-shared/services/comm.service';
import { StepApiService } from 'src/app/shared/general-shared/services/stepApi.service';
import { InfoScreenBuilderInterface } from 'src/app/shared/general-shared/models/interfaces/info-screen-builder.interface';

@Component({
  selector: 'app-info-screen-builder',
  templateUrl: './info-screen-builder.component.html',
})
export class InfoScreenBuilderComponent implements OnInit, OnDestroy {
  @Output()
  TemplateSaveComplete = new EventEmitter();

  trialID: number = parseInt(this.route.snapshot.queryParams['trialId']);
  @Input()
  commID?: any;
  steps: any;

  // Core Variables
  notificationDataTypes!: Array<NotificationDataTypeModel>;
  notificationContent: Array<NotificationDataTypeModel> = [];
  editingModule!: boolean;
  editedModule!: NotificationDataTypeModel;
  editedModuleIndex!: number;
  createEditForm!: FormGroup<InfoScreenBuilderInterface>;
  showButtonExternalLink!: boolean;
  // Loading Flags
  loadingAvailableAlgorithms!: boolean;
  loadingAvailableProducts?: boolean;
  loadingAvailableCTAProducts!: boolean;
  loadingInitialProducts!: boolean;
  // Available/Filtered vars
  availableAlgorithms!: Array<any>;
  filteredAlgorithms?: Array<any>;
  availableProducts!: Array<any>;
  availableCTAProducts!: Array<any>;
  currentChosenProduct: any;
  filteredProducts!: Array<any>;
  filteredCTAProducts?: Array<any>;
  ctaChosenProducts: Array<any> = [];
  ctaChipsInput: any;
  carouselValid!: boolean;
  hasCarousel!: boolean;
  videoNodeID!: string;
  carouselValidation!: ImageValidationInterface;
  showCustomNotificationPicker!: boolean;
  private subscriptions: Subscription = new Subscription();
  showDeleteConfirm!: boolean;
  showSaveConfirm!: boolean;

  existingTemplates: any[] = [];
  selectedTemplate: any;

  constructor(
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private messageService: MessageService,
    private errorHandleService: ErrorHandlerService,
    private algorithmsService: DecisionTreesService,
    private notificationBuilderService: NotificationBuilderService,
    private imageValidationService: ImageValidationService,
    private productsService: ProductsService,
    private commService: CommService,
    private stepsService: StepApiService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.getTemplates();
    this.getSteps();
    this.setupNotificationDataTypes();
    this.setImageValidation();

    if (this.commID && this.commID !== "LANDING") {
      this.getTemplate();
    }
    this.setupForm();
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  getSelectedStepName(): string {
    const selectedStep = this.steps.find((step: any) => step.id === this.createEditForm.controls.stepID.value);
    return selectedStep.name;
  }

  useTemplate() {
    this.createEditForm.controls['name'].setValue(this.selectedTemplate.name);
    this.createEditForm.controls['stepID'].setValue(this.selectedTemplate.step_id);

    this.notificationContent = this.selectedTemplate.body.landing_page.pages[0].content;
    this.notificationContent.forEach(content => {
      content.tempData = {};
      if (content.type === 'video') {
        content.tempData.sanitizedURL = this.sanitizer.bypassSecurityTrustResourceUrl(content.content);
      }
      if (content.type === 'product') {
        this.loadingInitialProducts = true;
        this.getAllModuleProducts();
        if (content && !content.hasOwnProperty('custom_summary')) {
          content.custom_summary = undefined;
        }
        content.tempData = {};
        content.tempData.hasProductTextOverride =
          typeof content.custom_summary === 'string' && content.custom_summary.length > 0 || false;
      }
      if (content.type === 'carousel') {
        this.hasCarousel = true;
        content.content = content.content.map((x: any, i: any) => {
          x.index = i;
          return x;
        });
        content.tempData = {};
        content.tempData.selectedCarouselOption = content.content[0];
      }
    });
    this.carouselValid = this.notificationBuilderService.validateCarousel(this.notificationContent);
  }

  getSteps() {
    this.stepsService.GetTrialSteps(this.trialID).subscribe({
      next: (res: any) => {
        this.steps = res;
        this.changeDetectorRef.detectChanges()
      },
      error: (error: any) => {
        this.errorHandleService.handleError(error.error);
      }
    })
  }

  getTemplate() {
    this.commService.getComm(this.trialID, this.commID).subscribe({
      next: (res: any) => {
        this.selectedTemplate = res;
        this.useTemplate();
      },
      error: (error: any) => {
        this.errorHandleService.handleError(error.error);
      }
    })
  }

  getTemplates() {
    this.commService.getAllComms(this.trialID).subscribe({
      next: (res: any) => {
        this.existingTemplates = res;
      },
      error: (error: any) => {
        this.errorHandleService.handleError(error.error);
      }
    })
  }

  setupNotificationDataTypes() {
    this.notificationDataTypes = this.commService.getNotificationDataTypes();
  }

  setupForm() {
    this.createEditForm = new FormGroup<InfoScreenBuilderInterface>({
      name: new FormControl('', [Validators.required]),
      stepID: new FormControl(0, [Validators.required])
    });

  }

  onSave() {
    this.submitForm();
  }

  submitForm() {
    if (this.createEditForm.valid) {
      this.showSaveConfirm = false;
      const clonedTemplate = JSON.parse(JSON.stringify(this.notificationContent));
      const notificationContent = {
        content: this.notificationBuilderService.cleanTemplate(clonedTemplate)
      };
      const notificationDetails: InfoScreenModel = {
        name: this.createEditForm.controls.name.value!,
        stepID: this.createEditForm.controls.stepID.value!,
      };

      let payload = {
        name: notificationDetails.name,
        trial_id: this.trialID,
        step_id: notificationDetails.stepID,
        type: "landing-page",
        notification_template_id: null,
        body: {
          landing_page: {
            page_indicator: null,
            done_action: null,
            pages: [
              notificationContent
            ]
          },
          sms: null
        }
      }

      // check if theres an existing comm id, and call update if there is
      // else its a new comm, so call create
      if (this.commID && this.commID !== "LANDING") {
        payload.notification_template_id = this.selectedTemplate.notification_template_id;
        this.subscriptions.add(
          this.commService.updateComm(this.trialID, this.commID, payload).subscribe({
            next: () => {
              this.commService.updateCommBody(payload.notification_template_id, payload.body.landing_page).subscribe({
                next: () => {
                  this.messageService.add({ key: 'dc', severity: 'success', detail: 'Successfully updated info screen template.' })
                  this.closeTemplate()
                },
                error: (data) => {
                  this.errorHandleService.handleError(data.error)
                }
              })
            },
            error: (data) => {
              this.errorHandleService.handleError(data.error)
            }
          })
        );
        return;
      }
      this.subscriptions.add(
        this.commService.createCommsTemplate(payload.body.landing_page).subscribe({
          next: (res: any) => {
            payload.notification_template_id = res.id ? res.id : null;
            this.commService.createComm(this.trialID, payload).subscribe({
              next: () => {
                this.messageService.add({ key: 'dc', severity: 'success', detail: 'Info screen template created successfully.' })
                this.closeTemplate()
              },
              error: (data) => {
                this.errorHandleService.handleError(data.error);
              }
            })
          },
          error: (data) => {
            this.errorHandleService.handleError(data.error);
          }
        })
      );
    }
  }

  handleCTAChange(event: any) {
    switch (event.value) {
      case 'symptom-checker':
        this.editedModule.content.triage_id = '';
        delete this.editedModule.content.link;
        delete this.editedModule.content.product_ids;
        this.getAllAlgorithms();
        break;
      case 'buy-products':
        delete this.editedModule.content.link;
        delete this.editedModule.content.triage_id;
        this.getAllCTAProducts();
        break;
      case 'external-link':
        this.showButtonExternalLink = true;
        this.editedModule.content.link = {};
        this.editedModule.content.link.web_view = undefined;
        delete this.editedModule.content.triage_id;
        delete this.editedModule.content.product_ids;
        break;
      default:
        delete this.editedModule.content.triage_id;
        delete this.editedModule.content.product_ids;
        delete this.editedModule.content.link;
        break;
    }
    this.resetCTAInputs();
  }

  getAllAlgorithms() {
    this.loadingAvailableAlgorithms = true;
    if (this.availableAlgorithms) {
      this.loadingAvailableAlgorithms = false;
      this.filteredAlgorithms = this.availableAlgorithms;
      return false;
    }
    this.subscriptions.add(this.algorithmsService.getAll().subscribe((data: any) => {
      this.loadingAvailableAlgorithms = false;
      this.availableAlgorithms = data.trees;
      this.filteredAlgorithms = this.availableAlgorithms;
    }, (data: any) => {
      this.loadingAvailableAlgorithms = false;
      this.errorHandleService.handleError(data.error);
    }));
    return;
  }

  getAllCTAProducts() {
    this.editedModule.content.product_ids = [];
    this.availableCTAProducts = [];
    this.loadingAvailableCTAProducts = true;
    this.notificationContent.forEach((module: any) => {
      if (module.type === 'product') {
        if (this.availableCTAProducts.findIndex(i => i.id === module.content.id) === -1) {
          this.availableCTAProducts.push(module.content);
        }
      }
    });
    this.filteredCTAProducts = this.availableCTAProducts;
    this.loadingAvailableCTAProducts = false;
  }

  getAllModuleProducts() {
    if (this.availableProducts) {
      this.filteredProducts = this.availableProducts;
      this.loadingAvailableProducts = false;
      this.notificationContent.forEach(content => {
        if (content.type === 'product') {
          const productDetails = this.availableProducts.filter(product => product.id === content.content);
          if (productDetails[0]) {
            content.content = cloneDeep(productDetails[0]);
          }
        }
      });
      this.loadingInitialProducts = false;
      return false;
    }
    if (!this.loadingAvailableProducts) {
      this.loadingAvailableProducts = true;
      this.subscriptions.add(this.productsService.getAllProducts().subscribe(data => {
        this.availableProducts = data.products;
        this.filteredProducts = this.availableProducts;
        this.loadingAvailableProducts = false;
        if (this.loadingInitialProducts) {
          this.notificationContent.forEach(content => {
            if (content.type === 'product') {
              const productDetails = this.availableProducts.filter(product => product.id === content.content);
              content.content = cloneDeep(productDetails[0]);
            }
          });
        }
        this.loadingInitialProducts = false;
      }, data => {
        this.loadingAvailableProducts = false;
        this.loadingInitialProducts = false;
        this.errorHandleService.handleError(data.error);
      }));
      return;
    }
    return;
  }

  setCTAProductChoices() {
    this.ctaChosenProducts = [];
    if (this.editedModule.content.product_ids) {
      this.editedModule.content.product_ids.forEach((product: any) => {
        this.availableProducts.forEach(item => {
          if (item.id === product) {
            this.ctaChosenProducts.push(item);
          }
        });
      });
    }
  }
  dropModule(event: any) {
    // If move from same container then shift in notificationContent
    if (event.previousContainer === event.container) {
      moveItemInArray(this.notificationContent, event.previousIndex, event.currentIndex);
    } else {
      // Else push to notificationContent
      let newContent;
      switch (event.item.element.nativeElement.id) {
        case this.notificationDataTypes[0].type:
          newContent = this.notificationDataTypes[0];
          break;
        case this.notificationDataTypes[1].type:
          newContent = this.notificationDataTypes[1];
          break;
        case this.notificationDataTypes[2].type:
          newContent = this.notificationDataTypes[2];
          break;
        case this.notificationDataTypes[3].type:
          newContent = this.notificationDataTypes[3];
          break;
        case this.notificationDataTypes[4].type:
          newContent = JSON.parse(JSON.stringify(this.notificationDataTypes[4]));
          break;
        case this.notificationDataTypes[5].type:
          newContent = this.notificationDataTypes[5];
          break;
        case this.notificationDataTypes[6].type:
          newContent = this.notificationDataTypes[6];
          break;
        case this.notificationDataTypes[7].type:
          if (this.notificationContent.find((x) => x.type === 'carousel') !== undefined) {
            newContent = undefined;
            this.messageService.add({
              key: 'dc', severity: 'error', detail: 'A carousel has already been added to this notification. Please remove the existing item before ' +
                'adding a new one.',
            })
          } else {
            newContent = this.notificationDataTypes[7];
            this.hasCarousel = true;
            this.carouselValid = false;
          }
          break;
      }
      if (!newContent) {
        return;
      }
      if (this.notificationContent.length === 0) {
        this.notificationContent.push({ ...newContent });
        return;
      }
      this.notificationContent.splice(event.currentIndex, 0, { ...newContent });
    }
  }

  editModule(event: any) {
    this.showCustomNotificationPicker = false;
    this.editingModule = true;
    this.editedModule = event.module;
    this.editedModuleIndex = event.index;
    // Sanitize Video URL if type video and no tempData
    if (this.editedModule.type === 'video' && !this.editedModule.tempData) {
      this.editedModule.tempData = {};
      this.editedModule.tempData.sanitizedURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.editedModule.content);
    }
    // If type call-to-action reset inputs
    if (this.editedModule.type === 'call-to-action') {
      this.resetCTAInputs();
    }
    // If type product get all products
    if (this.editedModule.type === 'product') {
      if (this.editedModule.content === this.notificationDataTypes[5].content) {
        this.editedModule.content.id = null;
      }
      this.getAllModuleProducts();
      this.editedModule.tempData = {
        hasProductTextOverride: typeof this.editedModule.custom_summary === 'string'
      };
    }
    if (this.editedModule.type === 'carousel' && !this.editedModule.tempData) {
      if (!this.editedModule.content || !this.editedModule.content.length) {
        this.editedModule.content = [];
      }
      this.editedModule.tempData = {
        selectedCarouselOption: null
      };
    }
  }

  resetCTAInputs() {
    if (this.editedModule.content.product_ids) {
      this.availableCTAProducts = [];
      this.notificationContent.forEach((module: any) => {
        if (module.type === 'product') {
          if (this.availableCTAProducts.findIndex(i => i.id === module.content.id) === -1) {
            this.availableCTAProducts.push(module.content);
          }
        }
        if (module.type === 'call-to-action' && module.content.type === 'buy-products') {
          this.setCTAProductChoices();
        }
      });
      this.filteredCTAProducts = this.availableCTAProducts;
      this.showButtonExternalLink = false;
      this.filteredAlgorithms = undefined;
      return false;
    }
    if (this.editedModule.content.triage_id || this.editedModule.content.type === 'symptom-checker') {
      if (!this.filteredAlgorithms) {
        if (this.availableAlgorithms) {
          this.filteredAlgorithms = this.availableAlgorithms;
        } else {
          this.getAllAlgorithms();
        }
      }
      this.showButtonExternalLink = false;
      this.filteredCTAProducts = undefined;
      this.loadingAvailableProducts = undefined;
      return false;
    }
    if (this.editedModule.content.link || this.editedModule.content.type === 'external-link') {
      this.showButtonExternalLink = true;
      this.filteredAlgorithms = undefined;
      this.filteredCTAProducts = undefined;
      return false;
    }
    this.showButtonExternalLink = false;
    this.filteredAlgorithms = undefined;
    this.filteredCTAProducts = undefined;
    return false;
  }

  removeModule(index: any) {
    this.editingModule = false;
    this.notificationContent.splice(index, 1);
    this.carouselValid = this.notificationBuilderService.validateCarousel(this.notificationContent);
  }

  confirmSave() {
    this.showSaveConfirm = true;
  }

  closeModal(type: any) {
    switch (type) {
      case 'save':
        this.showSaveConfirm = false;
        break;
    }
  }

  handleCarouselValidation() {
    this.carouselValid = this.notificationBuilderService.validateCarousel(this.notificationContent);
  }

  setImageValidation() {
    this.carouselValidation = this.imageValidationService.getImageValidationConfig('carousel');
  }

  closeTemplate() {
    this.TemplateSaveComplete.emit();
  }
}
