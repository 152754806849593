// Angular / RXJS
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
/**
 * AuthGuard
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  /**
   * @param router
   */
  constructor(private router: Router) {}

  /**
   * canActivate Guard function called on each navigation - Calls checkUser to validate the user is logged in before
   * accessing the current route.
   * @param next - The Route being navigated to
   * @param state - The current Route
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkUser();
  }
  /**
   * checkUser function - Checks the user has a token before navigating to the current route. When the user / token is
   * invalid the user is routed to the login page.
   * @private
   */
  private checkUser(): boolean {
    let guardCheck = false;
    const tokenLocalStorageCheck = localStorage.getItem('userToken');
    const userLocalStorageCheck = localStorage.getItem('currentUser');
    if (tokenLocalStorageCheck && userLocalStorageCheck) {
      guardCheck = true;
    }
    if (!guardCheck) {
      this.router.navigate(['/login']);
    }
    return guardCheck;
  }
}
