import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { InfoScreenTemplateInterface, SmsTemplateInterface } from '../../../shared/general-shared/models/interfaces/smsTemplate.interface';
import { CommFormInterface } from '../../../shared/general-shared/models/interfaces/comm-form.interface';
import { SmsTemplateApiService } from '../../../shared/general-shared/services/smsTemplateApi.service';
import { Table } from 'primeng/table';
import { ErrorHandlerService } from '../../../shared/general-shared/services/error-handler.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SendSMSTemplateModalComponent } from '../send-sms-template-modal/send-sms-template-modal.component';
import { CommService } from 'src/app/shared/general-shared/services/comm.service';

@Component({
  selector: 'app-comm-add-edit',
  templateUrl: './comm-add-edit.component.html',
})
export class CommAddEditComponent implements OnInit {
  form!: FormGroup<CommFormInterface>;
  commType: string[] = ['sms', 'landing-page'];
  availableSMSTemplates: SmsTemplateInterface[] = [];
  availableInfoTemplates: InfoScreenTemplateInterface[] = [];
  @Input()
  trialId!: number;
  filterColumn: string = '';
  filterValue: string = '';
  selectedSMSTemplate?: SmsTemplateInterface;
  selectedInfoTemplate?: InfoScreenTemplateInterface;
  @ViewChild('templateSMSTable') templateSMSTable!: Table;
  @ViewChild('templateLandingTable') templateLandingTable!: Table;

  ref?: DynamicDialogRef;

  constructor(
    private readonly smsTemplateApiService: SmsTemplateApiService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly commService: CommService,
    public dialogService: DialogService,
  ) {
  }

  ngOnInit(): void {
    this.form = new FormGroup<CommFormInterface>({
      id: new FormControl(),
      type: new FormControl(),
      templateId: new FormControl(),
    });
    this.getAvailableTemplates();
    this.getAvailableInfoScreenTemplates()
  }

  templateSaveComplete() {
    this.form.controls.templateId.setValue(undefined); // reset templateId
    this.getAvailableTemplates(); // refresh templates
    this.getAvailableInfoScreenTemplates();
  }

  newTemplate() {
    console.log(this.form.controls.type.value);
    if (this.form.controls.type.value === 'landing-page') {
      this.selectedInfoTemplate = undefined
      this.form.controls.templateId.setValue("LANDING")
      return;
    }
    this.selectedSMSTemplate = undefined
    this.form.controls.templateId.setValue('NEW');
  }

  clearSelectedTemplate() {
    this.getAvailableTemplates();
    this.getAvailableInfoScreenTemplates()
    this.selectedSMSTemplate = undefined;
    this.selectedInfoTemplate = undefined;
    this.form.controls.templateId.setValue(undefined);
  }

  private getAvailableInfoScreenTemplates() {
    this.commService.getAllComms(this.trialId).subscribe({
      next: (response) => {
        this.availableInfoTemplates = response;
      },
      error: (error) => {
        this.errorHandlerService.handleError(error);
      }
    })
  }

  private getAvailableTemplates() {
    this.smsTemplateApiService.getAllTemplatesForTrial(this.trialId).subscribe({
      next: (response) => {
        this.availableSMSTemplates = response;
      },
      error: (error) => {
        this.errorHandlerService.handleError(error);
      }
    });
  }

  filterStepTable() {
    this.templateSMSTable.filters = {};
    this.templateSMSTable.reset();
    if (this.filterValue.length === 0 || this.filterColumn.length === 0) {
      return;
    }
    this.templateSMSTable.filter(this.filterValue, this.filterColumn.toLowerCase(), 'contains');
  }

  filterInfoScreenTable() {
    this.templateLandingTable.filters = {};
    if (this.filterValue.length === 0 || this.filterColumn.length === 0) {
      this.templateLandingTable.reset();
      return;
    }

    this.templateLandingTable.filter(this.filterValue, this.filterColumn.toLowerCase(), 'contains');
  }

  templateSelected(template: SmsTemplateInterface | InfoScreenTemplateInterface, type: string) {
    if (type === 'sms') {
      this.selectedSMSTemplate = template as SmsTemplateInterface;
      this.form.controls.templateId.setValue(template.id);
    }

    if (type === 'landing-page') {
      this.selectedInfoTemplate = template as InfoScreenTemplateInterface;
      this.form.controls.templateId.setValue(template.id);
    }
  }


  showSendSMSParticipantModal() {
    this.ref = this.dialogService.open(SendSMSTemplateModalComponent, {
      data: { trialID: this.trialId },
      maximizable: true,
      closable: true,
      header: 'Send SMS Template to Participant',
      width: '30%',
    });
    this.ref.onClose.subscribe((response: boolean) => {
      if (response) {
        this.getAvailableTemplates();
        this.getAvailableInfoScreenTemplates()
      }
    });
  }
}
