import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {OverlayPanel} from 'primeng/overlaypanel';
import {ParticipantApiService} from '../../../shared/general-shared/services/participantApi.service';
import {ResolverInterface} from '../../../shared/general-shared/models/interfaces/resolver.interface';
import {StepApiService} from '../../../shared/general-shared/services/stepApi.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {statusForm} from '../../../shared/general-shared/models/interfaces/status-update.interface';

@Component({
  selector: 'app-step-status-overlay',
  templateUrl: './step-status-overlay.component.html',
})
export class StepStatusOverlayComponent implements OnInit {
  @ViewChild('statusOp') op!: OverlayPanel;
  @Output()
  StatusUpdated = new EventEmitter();
  trialId!: number;
  participantId!: number;
  stepId!: number;
  availableStatuses!: ResolverInterface[];
  currentTarget: any;
  form!: FormGroup<statusForm>;
  selectedValue: any;

  constructor(private participantApiService: ParticipantApiService, private stepApiService: StepApiService) {
  }

  public showPanel(event: any, trialId: number, participantId: number, stepId: number) {
    this.trialId = trialId;
    this.participantId = participantId;
    this.stepId = stepId;
    this.currentTarget = event.currentTarget;
    this.op.toggle(event, event.currentTarget);
  }

  statusSelected() {
    this.form.controls.status.setValue(this.selectedValue);
  }

  updateStatus() {
    let reason = this.form.controls.reason.getRawValue() as string;
    let statusId = +this.form.controls.status.getRawValue().value;
    this.participantApiService.updateStepStatus(this.trialId, this.stepId, this.participantId, statusId, reason).subscribe(() => {
      this.StatusUpdated.emit();
      this.op.hide();
    });
  }

  ngOnInit(): void {
    this.stepApiService.getStepStatuses().subscribe((response) => {
      this.availableStatuses = response;
    });
    this.form = new FormGroup<statusForm>({
      status: new FormControl(null, Validators.required),
      reason: new FormControl(null, Validators.required),
    });
  }
}
