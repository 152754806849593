// Angular / RXJS
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

/**
 * StorageService
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private dataSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});

  /**
   * getPersistedDataChanges function - Return local storage changes as an observable.
   * @description Get real time update of local storage changes.
   */
  getPersistedDataChanges(): Observable<any> {
    return this.dataSubject.asObservable();
  }

  /**
   * setData function - persists a key/value into local storage
   * @description - Any new data keys or changes will be updated to listeners via dataSubject.
   * @param {string} key
   * @param {any} value
   */
  setData(key: string, value: any) {
    if (this.storageAvailable()) {
      const jsonData = JSON.stringify(value);
      localStorage.setItem(key, jsonData);
    }
    const existingData = this.dataSubject.value;
    // Broadcast changes
    this.dataSubject.next({...existingData, [key]: value});
  }

  /**
   * getData function - gets a key/value pair from local storage
   * @description - The retrieved data will be updated on dataSubject for real time listeners.
   * @param {string} key
   */
  getData(key: string): any {
    if (!this.storageAvailable()) {
      return null;
    }
    const existingData = this.dataSubject.value;
    let data;
    try {
      // @ts-ignore
      data = JSON.parse(localStorage.getItem(key));
    } catch (e) {
      console.error(e);
      data = null;
    }
    if (!existingData.hasOwnProperty(key)) {
      // If local storage item doesn't exist in changes object then broadcast the newly pulled value.
      this.dataSubject.next({...existingData, [key]: data});
    }
    return data;
  }

  /**
   * removeData function - removes a key/value pair from local storage
   * @description - The removed data will be updated on dataSubject for real time listeners.
   * @param {string} key
   */
  removeData(key: string) {
    const existingData = this.dataSubject.value;
    delete existingData[key];
    if (this.storageAvailable()) {
      localStorage.removeItem(key);
    }
    // Broadcast changes
    this.dataSubject.next(existingData);
  }

  /**
   * storageAvailable function - returns true if local storage is available on the current browser.
   */
  storageAvailable(): boolean {
    try {
      const x = '__storage_test__';
      localStorage.setItem(x, x);
      localStorage.removeItem(x);
      return true;
    } catch (e) {
      return false;
    }
  }
}
