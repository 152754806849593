<div class="modalHeader">
  <p-button [label]="showLabel" [icon]="showIcon" iconPos="left" styleClass="p-button-outlined"
            (onClick)="toggleSelected()"></p-button>
  <div class="searchInputs">
  <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input type="text" pInputText
                 placeholder="Email Address"
                 [(ngModel)]="searchEmail"
                 (change)="basicSearch()"
                 data-automationid="tms-addParticipant-emailInput"
          />
        </span>
    <p-button label="advanced search"
              styleClass="p-button-link"
              (click)="advancedSearch()"
              data-automationid="tms-addParticipant-advancedSearch"></p-button>
  </div>
</div>
<p-table [value]="displayResults" selectionMode="multiple" [(selection)]="selectedItems" [loading]="loading">
  <ng-template pTemplate="header">
    <tr>
      <th></th>
      <th>Name</th>
      <th>Owner Name</th>
      <th>Owner Email</th>
      <th>Age</th>
      <th>Breed</th>
      <th>Gender</th>
      <th>Species</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-participant>
    <tr [pSelectableRow]="participant" *ngIf="(showSelected && participant.selected) || !participant.selected">
      <td></td>
      <td data-automationid="tms-addParticipant-table-name-item">{{participant.pet.name}}</td>
      <td data-automationid="tms-addParticipant-table-owner-item">{{participant.owner.name}}</td>
      <td data-automationid="tms-addParticipant-table-email-item">{{participant.owner.email}}</td>
      <td data-automationid="tms-addParticipant-table-age-item">{{participant.pet.age_in_months | agePipe}}</td>
      <td data-automationid="tms-addParticipant-table-breed-item">{{participant.pet.breeds}}</td>
      <td data-automationid="tms-addParticipant-table-gender-item">{{participant.pet.gender}}</td>
      <td data-automationid="tms-addParticipant-table-species-item">{{participant.pet.species}}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="loadingbody">
    <tr>
      <td>
        <p-skeleton></p-skeleton>
      </td>
    </tr>
  </ng-template>
</p-table>
<div class="modalFooter">
  <p-button label="Add Participants"
            styleClass="p-button-link"
            iconPos="left"
            icon="pi pi-user-plus"
            (onClick)="addParticipants()"
            data-automationid="tms-addParticipant-addParticipant"></p-button>
</div>
