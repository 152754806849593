// Modules
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GeneralSharedModule } from './shared/general-shared/general-shared.module';
import { NgModule, isDevMode } from '@angular/core';
// Components
import { AppComponent } from './app.component';
import { TrialDetailsComponent } from './trial-details/components/trial-details/trial-details.component';
// Services
import { MessageService } from 'primeng/api';
// Interceptors
import { AuthInterceptor } from './shared/general-shared/interceptors/auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StyleClassModule } from 'primeng/styleclass';
import { TrialEditModalComponent } from './trial-details/components/trial-edit-modal/trial-edit-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageModule } from 'primeng/message';
import { CommAddEditComponent } from './trial-details/components/comm-add-edit/comm-add-edit.component';
import {
  SmsTemplateAddEditComponent,
} from './trial-details/components/sms-template-add-edit/sms-template-add-edit.component';
import {
  ParticipantAddModalComponent,
} from './trial-details/components/participant-add-modal/participant-add-modal.component';
import {
  ParticipantAdvancedSearchModalComponent,
} from './trial-details/components/participant-add-modal/participant-advanced-search-modal/participant-advanced-search-modal.component';
import { RemoveParticipantModalComponent } from './trial-details/components/remove-participant-modal/remove-participant-modal.component';
import { SendSMSTemplateModalComponent } from './trial-details/components/send-sms-template-modal/send-sms-template-modal.component';
import { DialogService } from 'primeng/dynamicdialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NotificationBuilderPreviewComponent } from './trial-details/components/notification-builder-preview/notification-builder-preview.component';
import { NotificationBuilderEditComponent } from './trial-details/components/notification-builder-edit/notification-builder-edit.component';
import { NotificationBuilderOptionsComponent } from './trial-details/components/notification-builder-options/notification-builder-options.component';
import { ImageManagerComponent } from './trial-details/components/image-manager/image-manager.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputNumberModule } from 'primeng/inputnumber';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogModule } from 'primeng/dialog';
import { InfoScreenBuilderComponent } from './trial-details/components/info-screen-builder/info-screen-builder.component';

/**
 * AppModule
 */
@NgModule({
  declarations: [
    AppComponent,
    TrialDetailsComponent,
    TrialEditModalComponent,
    CommAddEditComponent,
    SmsTemplateAddEditComponent,
    ParticipantAddModalComponent,
    ParticipantAdvancedSearchModalComponent,
    RemoveParticipantModalComponent,
    SendSMSTemplateModalComponent,
    InfoScreenBuilderComponent,
    NotificationBuilderPreviewComponent,
    NotificationBuilderOptionsComponent,
    NotificationBuilderEditComponent,
    ImageManagerComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    GeneralSharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    StyleClassModule,
    FormsModule,
    MessageModule,
    ReactiveFormsModule,
    DragDropModule,
    ProgressSpinnerModule,
    MultiSelectModule,
    InputNumberModule,
    DialogModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    MessageService,
    DialogService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
