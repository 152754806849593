import {ApiService} from './api.service';
import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {DecisionTreeResponse} from '../models/interfaces/decision-trees.interface';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DecisionTreesService {
  constructor(private readonly apiService: ApiService) {
  }

  getAll(): Observable<DecisionTreeResponse> {
    return this.apiService.getData<DecisionTreeResponse>(environment.apiPrefix + '/v1/decision-trees');
  }
}
