import { environment } from '../../../../environments/environment';
import { lastValueFrom, Observable } from 'rxjs';
import { StepInterface } from '../models/interfaces/step.interface';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { PersistenceUnitsInterface } from '../models/interfaces/persistenceUnits.interface';
import { ResolverInterface } from '../models/interfaces/resolver.interface';

@Injectable({
  providedIn: 'root',
})
export class StepApiService {

  constructor(private readonly apiService: ApiService) {
    this.apiService = apiService;
  }

  GetPersistenceUnits() {
    return this.apiService.getData<PersistenceUnitsInterface[]>(`${environment.apiPrefix}/v1/trials/resolve/persistence`);
  }

  GetTrialSteps(trialId: number): Observable<StepInterface[]> {
    return this.apiService.getData<StepInterface[]>(`${environment.apiPrefix}/v1/trials/${trialId}/steps`);
  }

  getStepByID(trialID: number, stepID: number): Observable<any> {
    return this.apiService.getData(`${environment.apiPrefix}/v1/trials/${trialID}/steps/${stepID}`);
  }

  async CreateNewStep(step: any) {
    await lastValueFrom(this.apiService.postData(`${environment.apiPrefix}/v1/trials/${step.trial_id}/steps`, step));
  }

  async DeleteStep(trialId: number, stepId: number) {
    await lastValueFrom(this.apiService.deleteData(`${environment.apiPrefix}/v1/trials/${trialId}/steps/${stepId}`));
  }

  async UpdateStep(step: any) {
    await lastValueFrom(this.apiService.putData(`${environment.apiPrefix}/v1/trials/${step.trial_id}/steps/${step.id}`, step));
  }

  getStepStatuses(): Observable<ResolverInterface[]> {
    return this.apiService.getData<ResolverInterface[]>(`${environment.apiPrefix}/v1/trials/resolve/step-status`);
  }

  getRemovalReasons(): Observable<ResolverInterface[]> {
    return this.apiService.getData<ResolverInterface[]>(`${environment.apiPrefix}/v1/trials/resolve/reasons-for-removal`);
  }

  updateStepOrder(trialId: number, steps: { id: number, step_number: number, trial_id: number }[]) {
    return this.apiService.patchData<any>(`${environment.apiPrefix}/v1/trials/${trialId}/steps`, steps);
  }
}
