<form [formGroup]="form" (submit)="search()">
  <div class="searchSection">
    <label>Email Address</label>
    <div class="searchRow">
    <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input type="text" pInputText
                 placeholder="Email Address"
                 id="email"
                 formControlName="email"
                 data-automationid="tms-addParticipant-advanced-email"
          />
        </span>
    </div>
  </div>
  <div class="searchSection">
    <label for="petName">Pet Name</label>
    <div class="searchRow">
    <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input type="text" pInputText
                 placeholder="Pet Name"
                 id="petName"
                 formControlName="petName"
                 data-automationid="tms-addParticipant-advanced-name"
          />
        </span>
    </div>
  </div>
  <div class="searchSection">
    <label for="petName">Owner Name</label>
    <div class="searchRow">
    <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input type="text" pInputText
                 placeholder="Owner Name"
                 id="ownerName"
                 formControlName="ownerName"
                 data-automationid="tms-addParticipant-advanced-owner"
          />
        </span>
    </div>
  </div>
  <div class="modalFooter">
    <p-button label="Search"
              icon="pi pi-search-plus"
              iconPos="left"
              styleClass="p-button-link"
              data-automationid="tms-addParticipant-advanced-search"
              type="submit"></p-button>
  </div>
</form>
