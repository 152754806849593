<section>
  <article class="page-holder">
    <header class="create-edit-header">
      <p-button type="button" label="Close" styleClass="p-button-danger" icon="pi pi-times" (onClick)="closeTemplate()"></p-button>
      <p-button styleClass="p-button-info" label="Save" icon="pi pi-file"
        iconPos="left" [disabled]="notificationContent.length < 3 || !createEditForm.valid ||
          createEditForm.disabled || (!carouselValid && hasCarousel)" (onClick)="onSave()"></p-button>
    </header>
    <!-- Form Starts -->
    <section class="grid full-width">
      <article class="col-4">
        <form *ngIf="createEditForm" [formGroup]="createEditForm" (ngSubmit)="submitForm()" class="create-edit-form">

          <section class="full-width">
            <label>*Communication Name:</label>
            <div class="form-control-holder">
              <input [required]="true" pInputText formControlName="name" />
            </div>
          </section>

          <section class="full-width">
            <label>*Link to step:</label>
            <div class="form-control-holder">
              <p-dropdown [required]="true" [options]="steps" optionLabel="name" formControlName="stepID" optionValue="id"
                placeholder="Link to a step"></p-dropdown>
            </div>
          </section>

          <section class="full-width">
            <label>Template:</label>
            <div class="form-control-holder">
              <p-dropdown [options]="existingTemplates" [(ngModel)]="selectedTemplate" (onChange)="useTemplate()"
                optionLabel="name" [ngModelOptions]="{standalone: true}" placeholder="Select a template"></p-dropdown>
            </div>
          </section>

          <section class="full-width">
            <div *ngIf="notificationDataTypes">
              <app-notification-builder-options [createEditForm]="createEditForm"
                [notificationDataTypes]="notificationDataTypes"></app-notification-builder-options>
            </div>
          </section>
        </form>
      </article>
      <!-- Form Ends -->
      <!-- Notification Builder Starts -->
      <div *ngIf="loadingInitialProducts" class="loading-holder col-4">
        <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="8" fill="var(--surface-ground)"
          animationDuration=".5s"></p-progressSpinner>
        <p>Loading products</p>
      </div>
      <section class="notification-builder col-4" *ngIf="!loadingInitialProducts">
        <app-notification-builder-preview [createEditForm]="createEditForm"
          [notificationDataTypes]="notificationDataTypes" [notificationContent]="notificationContent"
          (dropModuleOut)="dropModule($event)" (editModuleOut)="editModule($event)"></app-notification-builder-preview>
      </section>
      <!-- Notification Builder Ends -->

      <!-- Module Editor Starts -->
      <section class="module-editor edit-form col-4" *ngIf="editingModule">
        <app-notification-builder-edit [editedModule]="editedModule" [notificationDataTypes]="notificationDataTypes"
          [createEditForm]="createEditForm" [carouselValidation]="carouselValidation" [ctaChipsInput]="ctaChipsInput"
          [ctaChosenProducts]="ctaChosenProducts" [editedModuleIndex]="editedModuleIndex"
          [filteredAlgorithms]="filteredAlgorithms" [filteredCTAProducts]="filteredCTAProducts"
          [filteredProducts]="filteredProducts" [loadingAvailableAlgorithms]="loadingAvailableAlgorithms"
          [loadingAvailableCTAProducts]="loadingAvailableCTAProducts"
          [loadingAvailableProducts]="loadingAvailableProducts" [showButtonExternalLink]="showButtonExternalLink"
          [videoNodeID]="videoNodeID" [availableAlgorithms]="availableAlgorithms"
          [availableCTAProducts]="availableCTAProducts" [availableProducts]="availableProducts"
          (handleCTAChangeEvent)="handleCTAChange($event)" (removeModuleEvent)="removeModule($event)"
          (validateCarouselEvent)="handleCarouselValidation()"></app-notification-builder-edit>
      </section>
      <!-- Module Editor Ends -->
    </section>
  </article>
</section>
<!-- Save Modal Starts -->
<p-dialog [visible]="showSaveConfirm" [closable]="false" [draggable]="false" [modal]="true">
  <section class="confirmation-modal">
    <p>Changes will be reflected on the published info screen template and will be shown immediately.<br> Are you sure
      you want
      to continue?</p>
    <div class="btn-holder">
      <p-button label="Yes" styleClass="p-button-sm p-button-success" icon="pi pi-check" iconPos="right"
        (onClick)="submitForm()"></p-button>
      <p-button label="No" styleClass="p-button-sm p-button-danger" icon="pi pi-times" iconPos="right"
        (onClick)="closeModal('save')"></p-button>
    </div>
  </section>
</p-dialog>
<!-- Save Modal Ends -->
