import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {SmsTemplateInterface} from '../models/interfaces/smsTemplate.interface';
import {map, Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SmsTemplateApiService {
  constructor(private readonly apiService: ApiService) {
  }

  getAllTemplatesForTrial(trialId: number): Observable<SmsTemplateInterface[]> {
    return this.apiService.getData<SmsTemplateInterface[]>(`${environment.apiPrefix}/v1/sms/trials/${trialId}/templates`);
  }

  getTemplate(trialId: number, smsTemplateId: number): Observable<SmsTemplateInterface> {
    return this.apiService.getData<SmsTemplateInterface>(`${environment.apiPrefix}/v1/sms/trials/${trialId}/templates/${smsTemplateId}`);
  }

  saveTemplate(template: SmsTemplateInterface): Observable<number> {
    const apiResponse = this.apiService.postData<SmsTemplateInterface>(`${environment.apiPrefix}/v1/sms/trials/templates`, template);
    return apiResponse.pipe(map((resp) => resp.id));
  }

  updateTemplate(template: SmsTemplateInterface): Observable<number> {
    const apiResponse = this.apiService.putData<SmsTemplateInterface>(`${environment.apiPrefix}/v1/sms/trials/templates/${template.id}`, template);
    return apiResponse.pipe(map((resp) => resp.id));
  }
}
