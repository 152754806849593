// Angular / RXJS
import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {filter, map, Observable} from "rxjs";
// Services
import {AuthService} from "./shared/general-shared/services/auth.service";
/**
 * AppComponent
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Trial Management System';
  showHeading: Observable<any> = new Observable<any>();
  /**
   * @param authService
   * @param router
   */
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router
  ) {
  }
  /**
   * Angular init function - sets showHeading Observable from getHeadingChanges
   */
  ngOnInit(): void {
    this.showHeading = this.getHeadingChanges();
  }
  /**
   * getHeadingChanges function - updates heading status from router navigation changes.
   * @description - Hides header when on login pages.
   */
  getHeadingChanges() {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: any) => {
        return event.url !== '/login' && event?.urlAfterRedirects !== '/login';
      })
    );
  }
}
