import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommService {
  constructor(private readonly apiService: ApiService) { }

  getComm(trialID: number, commID: number): Observable<any> {
    return this.apiService.getData<any>(`${environment.apiPrefix}/v1/trials/${trialID}/comms/${commID}`);
  }

  getAllComms(trialID: number): Observable<any> {
    return this.apiService.getData<any>(`${environment.apiPrefix}/v1/trials/${trialID}/comms`);
  }

  createComm(trialID: number, payload: any): Observable<any> {
    return this.apiService.postData<any>(`${environment.apiPrefix}/v1/trials/${trialID}/comms`, payload);
  }

  updateComm(trialID: number, commID: number, payload: any): Observable<any> {
    return this.apiService.putData<any>(`${environment.apiPrefix}/v1/trials/${trialID}/comms/${commID}`, payload);
  }

  createCommsTemplate(payload: any): Observable<any> {
    return this.apiService.postData<any>(`${environment.apiPrefix}/v1/trials/templates/notifications`, payload);
  }

  updateCommBody(notificationID: any, payload: any): Observable<any> {
    return this.apiService.putData<any>(`${environment.apiPrefix}/v1/trials/templates/notifications/${notificationID}`, payload);
  }

  deleteComm(trialID: number, commID: number): Observable<any> {
    return this.apiService.deleteData(`${environment.apiPrefix}/v1/trials/${trialID}/comms/${commID}`);
  }

  getCommsTemplates(trialID: number) {
    return this.apiService.getData<any>(`${environment.apiPrefix}/v1/trials/${trialID}/templates/notifications`);
  }


  getNotificationDataTypes() {
    return [
      {
        label: 'Title',
        type: 'title',
        content: 'Placeholder title'
      },
      {
        label: 'Body text',
        type: 'text',
        content: 'Placeholder body text'
      },
      {
        label: 'Image',
        type: 'image',
        content: './assets/placeholders/image-placeholder.png'
      },
      {
        label: 'Video',
        type: 'video',
        content: './assets/placeholders/video-placeholder.png'
      },
      {
        label: 'Button',
        type: 'call-to-action',
        content: {
          label: 'Placeholder button text',
          type: 'nurse-consultation',
        }
      },
      {
        label: 'Product',
        type: 'product',
        content: {
          id: null,
          name: 'Placeholder product',
          description: 'Some placeholder product description',
          images: [
            {
              image_url: './assets/placeholders/image-placeholder.png'
            }
          ],
          price: {
            price: 0
          }
        },
        custom_summary: ''
      },
      {
        label: 'Delimiter',
        type: 'delimiter',
        content: ''
      },
      {
        label: 'Carousel',
        type: 'carousel',
        content: './assets/placeholders/carousel-placeholder.png'
      },
    ];
  }

  getButtonOptions() {
    return [
      {
        label: 'Nurse consultation',
        value: 'nurse-consultation'
      },
      {
        label: 'Free Nurse consultation',
        value: 'free-nurse-consultation'
      },
      {
        label: 'Vet consultation',
        value: 'vet-consultation'
      },
      {
        label: 'Free Vet consultation',
        value: 'free-vet-consultation'
      },
      {
        label: 'Symptom checker',
        value: 'symptom-checker'
      },
      {
        label: 'Buy products',
        value: 'buy-products'
      },
      {
        label: 'External link',
        value: 'external-link'
      },
      {
        label: 'Dismiss',
        value: 'dismiss'
      }
    ];
  }
}
