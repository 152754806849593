<form [formGroup]="form" (submit)="this.saveTrial()" class="editTrialModalForm">
  <div class="modalSubHeaderBar">
    <h2 class="modalSubHeader">General Info</h2>
    <p-button
      styleClass="p-button-danger"
      label="Delete"
      icon="pi pi-trash"
      data-automationId="tms-trial-deleteButton"
      (click)="deleteTrial()"></p-button>
  </div>
  <div class="formgrid grid">
    <div class="field col-12">
      <label for="trialName">Trial Name</label>
      <input
        id="trialName"
        type="text"
        formControlName="name"
        pInputText
        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
        data-automationid="tms-edit-trialName-input"
      >
      <p-message class="scalein animation-duration-500 animation-ease-in" severity="error"
                 text="Name is required"
                 *ngIf="!form.controls.name.valid && form.controls.name.dirty"></p-message>
    </div>
    <div class="field col-12">
      <label for="trialDescription">Summary</label>
      <textarea id="trialDescription" rows="5" cols="30" pInputTextarea formControlName="description"
                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                data-automationid="tms-edit-trialDesc-input"></textarea>
      <p-message class="scalein animation-duration-500 animation-ease-in" severity="error"
                 text="Description is required"
                 *ngIf="!form.controls.description.valid && form.controls.description.dirty"></p-message>
    </div>
  </div>
  <p-button
    styleClass="p-button-link"
    [disabled]="!form.valid"
    type="submit"
    label="Save"
    data-automationid="tms-edit-save-button"
    icon="pi pi-check"
  ></p-button>
</form>
