<div style="margin: 10px">
  <div *ngFor="let reason of removalReasons" class="field-checkbox">
    <p-radioButton class="reason-radio"
                   [inputId]="reason.value.toString()"
                   name="reason"
                   [value]="reason.value"
                   [(ngModel)]="selectedReason"
                   data-automationid="tms-removeParticipantOptions">
    </p-radioButton>
    <label [for]="reason.value">{{ reason.display_value }}</label>
  </div>

  <div class="other-option" *ngIf="selectedReason == otherReasonId">
    <textarea #pInputTextarea
              type="text"
              maxlength="5000"
              class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
              data-automationid="tms-removeParticipantReasonOtherTextBox"
              [(ngModel)]="otherReason"
              (keyup)="updateNumOfChars(pInputTextarea.value.length)"
    ></textarea>
    <label>{{5000 - numberOfChars}} characters remaining </label>
  </div>
</div>
<p-button type="button" [disabled]="!selectedReason" label="Remove"
          styleClass="p-button-danger"
          icon="pi pi-times"
          (click)="removeParticipant()"
          data-automationid="tms-removeParticipantFinalButton">
</p-button>
