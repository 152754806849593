import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertToHoursFrom',
})
export class ConvertToHoursFrom implements PipeTransform {

  // convert time into hours
  transform(value: any, unit: string): any {
    switch (unit) {
      case 'hours':
        return value;
      case 'days':
        return value * 24;
      case 'weeks':
        return value * 168;
      case 'months':
        return value * 730;
      default:
        return value;
    }
  }

}
