import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { PetSearchResponseInterface } from '../models/interfaces/petSearchResponse.interface';

@Injectable({
  providedIn: 'root',
})
export class PetSearchApiService {
  constructor(private readonly apiService: ApiService) {
  }

  public searchForAPet(emailAddress: string, petName: string, ownerName: string): Observable<PetSearchResponseInterface> {
    let queryString = `?name=${petName}`;
    if (emailAddress)
      queryString = queryString + `&email=${emailAddress}`;
    if (ownerName)
      queryString = queryString + `&owner=${ownerName}`;
    return this.apiService.getData<PetSearchResponseInterface>(environment.apiPrefix + '/v1/pets/search' + queryString);
  }

  public getPetDetailsByID(petID: number): Observable<any> {
    return this.apiService.getData<any>(environment.apiPrefix + `/v1/pets/${petID}`);
  }
}
