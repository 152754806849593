import { Component, ViewEncapsulation } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TransformedParticipant } from 'src/app/shared/general-shared/models/interfaces/participant.interface';
import { AddressApiService } from 'src/app/shared/general-shared/services/address.service';
import { ErrorHandlerService } from 'src/app/shared/general-shared/services/error-handler.service';
import { PetSearchApiService } from 'src/app/shared/general-shared/services/petSearchApi.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-participant-information-modal',
  templateUrl: './participant-information-modal.component.html',
  styleUrls: ['./participant-information-modal.component.scss']
})
export class ParticipantInformationModalComponent {

  participant!: TransformedParticipant;
  insurance!: string;
  breeds!: string;
  species!: string;
  address!: string;

  constructor(
    private readonly addressApiService: AddressApiService,
    private readonly petSearchApiService: PetSearchApiService,
    private readonly config: DynamicDialogConfig,
    private readonly ref: DynamicDialogRef,
    private readonly errorHandler: ErrorHandlerService,
  ) { }

  ngOnInit() {
    this.participant = this.config.data.participant;
    this.getPetDetails();
    this.getUserAddress();
  }

  getPetDetails() {
    this.petSearchApiService.getPetDetailsByID(this.participant.id).subscribe({
      next: (results) => {
        this.insurance = results.pet.insurance;
        this.breeds = results.pet.breeds.join(', ');
        this.species = results.pet.species;
      },
      error: (err) => {
        this.errorHandler.handleError(err.error);
      }
    });
  }

  getUserAddress() {
    this.addressApiService.getUserAddressByID(this.participant.user_id!).subscribe({
      next: (results) => {
        if (results.addresses.length == 0) {
          this.address = "";
          return;
        }

        let defaultAddress = results.addresses.find((address) => address.default)
        if (!defaultAddress) {
          this.address = "";
          return;
        }

        const {
          house_number,
          street,
          city,
          county,
          postcode,
          country,
        } = defaultAddress!

        this.address = `${house_number}\n${street}\n${city}\n${county}\n${postcode}\n${country}`
      },
      error: (err) => {
        this.errorHandler.handleError(err.error);
      }
    });
  }

  copyToClipboard(value: any) {
    navigator.clipboard.writeText(value);
  }
}
