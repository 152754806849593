<p-overlayPanel #statusOp>
  <p-table [value]="availableStatuses" selectionMode="single" [(selection)]="selectedValue"
           (selectionChange)="statusSelected()">
    <ng-template pTemplate="body" let-status>
      <tr [pSelectableRow]=status>
        <td data-automationId="tms-participant-step-status-change-row">
          <app-status-tag status="{{status.display_value}}"></app-status-tag>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <label>Please add a reason for the status change</label>
  <form (submit)="updateStatus()" [formGroup]="form">
    <div class="reasonForm">
      <input
        id="reason"
        type="text"
        formControlName="reason"
        placeholder="Reason"
        pInputText
        data-automationid="tms-participant-status-change-reason"
      >
      <p-button label="Update Status" type="submit" [disabled]="!form.valid"
                data-automationId="tms-participant-step-status-change-button"></p-button>
    </div>
  </form>
</p-overlayPanel>
