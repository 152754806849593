import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TrialFormInterface} from '../../../shared/general-shared/models/interfaces/trial-form.interface';
import {TrialApiService} from '../../../shared/general-shared/services/trialApi.service';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TrialInterface} from '../../../shared/general-shared/models/interfaces/trial.interface';
import {Router} from '@angular/router';

@Component({
  selector: 'app-trial-edit-modal',
  templateUrl: './trial-edit-modal.component.html',
})
export class TrialEditModalComponent implements OnInit {

  constructor(
    private trialApiService: TrialApiService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private router: Router) {
  }

  form!: FormGroup<TrialFormInterface>;
  trial?: TrialInterface;
  loading: boolean = false;

  ngOnInit(): void {
    this.trial = this.config.data;
    this.form = new FormGroup<TrialFormInterface>(
      {
        id: new FormControl(this.trial?.id),
        name: new FormControl(this.trial?.name, Validators.required),
        description: new FormControl(this.trial?.description, Validators.required),
      },
    );
  }

  async saveTrial() {
    await this.trialApiService.UpdateTrial(this.form.getRawValue());
    this.ref.close(true);
  }

  async deleteTrial() {
    await this.trialApiService.DeleteTrial(this.trial!.id!);
    await this.router.navigate(['/dashboard']);
    this.ref.close(false);
  }
}
