// Angular / RXJS
import {Component, OnInit} from '@angular/core';
// Services
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MenuItem} from "primeng/api";

/**
 * Main Header Component
 */
@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
})
export class MainHeaderComponent implements OnInit {
  menuItems: Array<MenuItem> = [];
  logoutItems: Array<MenuItem> = [{
    label: 'Log out',
    icon: 'pi pi-sign-out',
    command: () => this.logout(),

    automationId: 'data-tms-top-bar-logout-button'
  }];
  trialId!: number;

  /**
   *
   * @param authService - Handle logout function,
   * @param router
   * @param activatedRoute
   */
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute
  ) {
  }

  /**
   * Angular init function - Calls setMenuItems to populate navigation bar.
   */
  ngOnInit() {
    this.setTrialId();
  }

  /**
   * setMenuItems function - Populates navigation bar with link information (Label, Icon, Route)
   */
  setMenuItems() {
    const currentPage = this.router.url;
    this.menuItems = [
      {
        label: 'Dashboard',
        icon: 'pi pi-th-large',
        routerLink: 'dashboard',
        styleClass: currentPage === '/dashboard' ? 'active' : '',
        automationId: 'tms-top-bar-dashboard-button',
      }
    ]
  }

  setTrialId(): void {
    this.activatedRoute?.queryParams.subscribe(params => {
      this.trialId = params['trialId'];
      this.setMenuItems();
    });
  }

  /**
   * logout function - Calls authService.logout to handle log out functionality.
   */
  async logout() {
    await this.authService.logout();
  }
}
