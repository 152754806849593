// Angular / RXJS
import { Injectable } from '@angular/core';
// Services
import {MessageService} from "primeng/api";
// Interfaces
import {ErrorInterface, RemedyInterface} from "../models/interfaces/error.interface";
// Third Party
import {isString} from "lodash-es";
/**
 * Error Handler Service
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  /**
   * @param messageService - Display toast messages using NG Prime
   */
  constructor(
    private readonly messageService: MessageService
  ) { }
  /**
   * handleError function - Generates Error message from API error or provided string and
   * Display error Toast using NG Prime messageService.
   * @TODO: Log error to analytics service
   * @param {ErrorInterface | string} error
   */
  handleError(error: ErrorInterface | string): void {
    const defaultMessage = 'Error with API request, no cause given.';
    let message: string | undefined = '';

    if (typeof error === 'string') {
      message = error as string;
    } else {
      error = error as ErrorInterface;

      if (this.hasUserMessage(error)) {
        message = error.user_message;
      }

      if (this.hasCause(error) && !this.hasUserMessage(error)) {
        message = `${error.cause}`;
      }
      // Display remedies at the end of the alert in a comma separated list.
      if (this.hasRemedies(error) && !this.hasUserMessage(error)) {
          message = `${message}, ${error.remedy}`;
      }
    }
    // If message is not set use the default error message.
    if (!message || message.length === 0) {
      message = defaultMessage;
    }
    this.messageService.add({
      severity: 'error',
      detail: message
    })
    console.error(message);
  }
  /**
   * hasCause function - returns true if ErrorInterface contains property 'cause' and cause is a valid string.
   * @param {ErrorInterface} error
   * @returns boolean
   */
  hasCause(error: ErrorInterface): boolean {
    return error && error.hasOwnProperty('cause') && isString(error.cause);
  }
  /**
   * hasRemedies function - returns true if ErrorInterface contains property 'remedy' and remedy is a valid string.
   * @param {ErrorInterface} error
   * @returns boolean
   */
  hasRemedies(error: ErrorInterface): boolean {
    return error && error.hasOwnProperty('remedy') && isString(error.remedy);
  }
  /**
   * hasUserMessage function - returns true if ErrorInterface contains property 'user_message' and user_message is a
   * valid string.
   * @description - This function will return false if user_message contains 'jg-' as this is identified that the API
   * message is not suitable for the end user.
   * @param {ErrorInterface} error
   * @returns boolean
   */
  hasUserMessage(error: ErrorInterface): boolean {
    return error && error.hasOwnProperty('user_message') && !error.user_message?.toLowerCase().includes('jg-');
  }
}
