<form [formGroup]="form" class="smsForm" (submit)="sendSMS()">
  <div class="dropdown">
    <label for="petID">Select Participant:</label>
    <p-dropdown id="petID" [options]="availableParticipants"
                formControlName="petID"
                optionValue="code"
                placeholder="Select a participant"
                optionLabel="name"
                appendTo="body">
    </p-dropdown>
  </div>
  <div class="dropdown">
    <label for="templateID">Select Template:</label>
    <p-dropdown id="templateID" [options]="availableTemplates"
                optionValue="code"
                formControlName="templateID"
                placeholder="Select a template"
                optionLabel="name"
                appendTo="body">
    </p-dropdown>
  </div>

  <div class="submitButton">
    <p-button class="send-sms" type="submit" [disabled]="!form.valid" label="Send SMS" styleClass="p-button-warning"
              icon="pi pi-send">
    </p-button>
  </div>
</form>
