// Angular / RXJS
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { delay, Observable, Subject, Subscription, takeUntil } from 'rxjs';
// Interfaces
import { TrialInterface } from '../../../shared/general-shared/models/interfaces/trial.interface';
// Third Party
import { MenuItem } from 'primeng/api';
import { TrialApiService } from '../../../shared/general-shared/services/trialApi.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TrialEditModalComponent } from '../trial-edit-modal/trial-edit-modal.component';
import { ParticipantAddModalComponent } from '../participant-add-modal/participant-add-modal.component';
import { ParticipantApiService } from 'src/app/shared/general-shared/services/participantApi.service';
import { ErrorHandlerService } from 'src/app/shared/general-shared/services/error-handler.service';
import {
  Participant,
  TransformedParticipant,
} from 'src/app/shared/general-shared/models/interfaces/participant.interface';
import { Table } from 'primeng/table';
import { StepStatusOverlayComponent } from '../step-status-overlay/step-status-overlay.component';
import { RemoveParticipantModalComponent } from '../remove-participant-modal/remove-participant-modal.component';
import { ParticipantInformationModalComponent } from '../participant-information-modal/participant-information-modal.component';
import { BreadcrumbService } from 'src/app/shared/general-shared/services/breadcrumb.service';

/**
 * TrialDetailsComponent
 */
@Component({
  selector: 'app-trial-details',
  templateUrl: './trial-details.component.html',
  providers: [DialogService],
})
export class TrialDetailsComponent implements OnInit {
  trialId!: number;
  trialDetails!: TrialInterface | undefined;
  loading = false;

  participants: TransformedParticipant[] = [];
  @ViewChild('participantsTable') participantsTable!: Table;
  @ViewChild('statusOverlay') statusOverlay!: StepStatusOverlayComponent;
  filterOptions = [
    { label: 'Pet Name', value: 'pet_name' },
    { label: 'Pet Status', value: 'pet_status' },
    { label: 'Step Name', value: 'step_name' },
    { label: 'Step Status', value: 'step_status' },
    { label: 'Step ID', value: 'step_id' },
    { label: 'Last Active', value: 'last_active' },
    { label: 'Step Start Date', value: 'step_start_date' },
    { label: 'Duration Ends', value: 'duration_ends' },
    { label: 'Visibility Ends', value: 'visibility_ends' },
  ];
  filterColumn: string = '';
  filterValue: string = '';

  ref?: DynamicDialogRef;

  breadcrumbID: string = this.constructor.name;
  breadcrumbSubscription!: Subscription;
  breadcrumbItems!: MenuItem[];
  breadcrumbHome: MenuItem = this._breadcrumbService.breadcrumbHome;

  /**
   * @param activatedRoute - Access route's query params.
   * @param trialApiService
   * @param participantsApiService
   * @param errorHandlerService
   * @param router
   * @param dialogService
   * @param _breadcrumbService
   */
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly trialApiService: TrialApiService,
    private readonly participantsApiService: ParticipantApiService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly router: Router,
    public dialogService: DialogService,
    public _breadcrumbService: BreadcrumbService
  ) {
  }

  /**
   * Angular init function - Set trial details from route ID and setup breadcrumb details.
   */
  ngOnInit() {
    this._breadcrumbService.clearbreadcrumbs()
    this.setTrialId();
    this.setTrialDetails();
    this.getAllParticipants();
  }

  /**
   * getTrial function - returns API observable with current trial details.
   * @returns {Observable<TrialInterface>}
   */
  private getTrial(): Observable<TrialInterface> {
    return this.trialApiService.GetTrial(this.trialId);
  }

  /**
 * setTrialId function - Sets trialId from route query params.
 */
  private setTrialId(): void {
    const id = +this.activatedRoute?.snapshot?.queryParams['trialId'];
    if (!isNaN(id)) {
      this.trialId = id;
    }
  }

  /**
   * setTrialDetails function - subscribe and set trialDetails from API. Update breadcrumb items
   * with new context.
   */
  private setTrialDetails(): void {
    this.loading = true;
    this.getTrial().pipe(
      delay(2000),
    ).subscribe((result) => {
      this.trialDetails = result;
      this.loading = false;
      this._breadcrumbService.addBreadcrumb({ id: this.breadcrumbID, label: this.trialDetails?.name || 'Details', routerLink: `/trial`, queryParams: { "trialId": this.trialId.toString() } });
      this.breadcrumbSubscription = this._breadcrumbService.breadcrumbItems.subscribe(items => {
        this.breadcrumbItems = items;
      });
    });
  }

  /**
   * editTrial function - route to edit page for current trial
   */
  async editTrial() {
    this.ref = this.dialogService.open(TrialEditModalComponent, {
      data: this.trialDetails,
      maximizable: true,
      closable: true,
      header: 'Edit Information',
    });
    this.ref.onClose.subscribe((response: boolean) => {
      if (response) {
        this.setTrialDetails();
      }
    });
  }

  getAllParticipants() {
    this.participantsApiService.getAllParticipants(this.trialId).subscribe((response) => {
      this.participants = response.map((participant) => {
        return {
          id: participant.pet.id!,
          user_id: participant.user.id!,
          pet_name: participant.pet.name!,
          user_name: participant.user.name!,
          pet_status: participant.trial_status!,
          pet_gender: participant.pet.gender!,
          pet_age: participant.pet.age!,
          user_email: participant.user.email!,
          user_phone: participant.user.phone!,
          step_name: participant.current_step.name!,
          step_status: participant.current_step.status!,
          step_start_date: participant.current_step.step_start_date ? new Date(participant.current_step.step_start_date) : null,
          duration_ends: participant.current_step.duration_ends ? new Date(participant.current_step.duration_ends) : null,
          visibility_ends: participant.current_step.visibility_ends ? new Date(participant.current_step.visibility_ends) : null,
          step_id: participant.current_step.id!,
          last_active: participant.current_step.timestamp ? new Date(participant.current_step.timestamp) : null,
          appointment_time: participant.current_step.appointment_time ? new Date(participant.current_step.appointment_time) : null,
        };
      });
    }, error => {
      this.errorHandlerService.handleError(error);
    });
  }

  showAddParticipant() {
    this.ref = this.dialogService.open(ParticipantAddModalComponent, {
      data: this.trialId,
      maximizable: true,
      closable: true,
      header: 'Add Participants',
      width: '70%',
    });
    this.ref.onClose.subscribe((response: boolean) => {
      if (response) {
        this.getAllParticipants();
      }
    });
  }

  filterParticipantsTable() {
    this.participantsTable.filters = {};
    this.participantsTable.reset();

    if (this.filterValue.length === 0 || this.filterColumn.length === 0) return;

    this.participantsTable.filter(this.filterValue, this.filterColumn.toLowerCase(), 'contains');
  }

  changeStepStatus(participantId: number, stepId: number, event: any) {
    this.statusOverlay.showPanel(event, this.trialId, participantId, stepId);
  }

  showRemoveParticipantModal(event: Event, participantID: number, trialID: number) {
    event.stopPropagation();
    this.ref = this.dialogService.open(RemoveParticipantModalComponent, {
      data: { trialID, participantID },
      maximizable: true,
      closable: true,
      header: 'Remove Participants',
      width: '30%',
    });
    this.ref.onClose.subscribe((response: boolean) => {
      if (response) {
        this.getAllParticipants();
      }
    });
  }

  showParticipantInformation(event: Event, participant: TransformedParticipant) {
    event.stopPropagation();
    this.ref = this.dialogService.open(ParticipantInformationModalComponent, {
      data: { participant },
      maximizable: true,
      closable: true,
      width: '70%',
      header: "Participant Information"
    });
  }

  viewParticipantView(participant: TransformedParticipant) {
    this.router.navigate(
      [`./trial/${this.trialId}/participant/${participant.id}`], { state: { participant: participant } },
    )
  }

  ngOnDestroy() {
    if (this.breadcrumbSubscription) {
      this.breadcrumbSubscription.unsubscribe();
    }
  }
}
